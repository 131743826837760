import './Navbar.css';
import '../../1_MediaAssets/Styles/App.css';
import Burger from '../../1_MediaAssets/Homepage/Burger.png';
import LogoWhite from '../../1_MediaAssets/Homepage/FullLogoWhite.png';
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

const NavbarMain = () => {
  const [isChecked, setIsChecked] = useState(false); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const closeNavvy = () => {
    setIsChecked(!isChecked);
  };

  const signout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        localStorage.removeItem('userId');
        console.log('Signed Out');
        window.location.href = '/';
    }).catch((error) => {
        console.log(error);
    });
  }

  return (
    <>
      <nav ref={navRef} style={{ zIndex: '100' }}>
        <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange} />

        <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white', margin: '0px 8px 0px 15px', zIndex: '2'})} to="/">
          <img src={LogoWhite} alt='LogoWhite' style={{ width: '215px', height: '30px', marginLeft: '5px', marginRight: '-20px' }}></img>
        </NavLink>

        <label htmlFor="check" className="checkbtn">
          <img src={Burger} alt='Burger' className='Burger' style={{ width: '27px', height: '22px', filter: 'brightness(1000%)' }}></img>
        </label>

        <ul style={{ zIndex: '1' }}>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/">
              Home 
            </NavLink> 
          </li>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/About">
              About
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/UpcomingCourses">
              Upcoming courses
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/Faculty">
              Faculty
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/Resources">
              Resources
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/Contact">
              Contact
            </NavLink>
          </li>
          <li className='DPhiderReverse'>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#8FF2CC' : 'white' })} onClick={closeNavvy} to="/Login">
              Dashboard
            </NavLink>
          </li>
          {isLoggedIn && (   
            <li>
              <NavLink className="nav-link" style={({ color: 'white' })} onClick={closeNavvy} to="/EditProfile">
                Edit profile
              </NavLink>
            </li>
          )}
          {isLoggedIn && (   
            <li>
              <button onClick={signout} className="nav-link" style={{color: 'white'}}>Sign out</button>
            </li>
          )}

          <NavLink className="nav-link DPhider" onClick={closeNavvy} to="/Login">
            <button style={{ position: 'absolute', right: '0', top: '5px', background: '#8FF2CC', color: 'black', borderRadius: '100px', marginRight: '10px', fontSize: '13px', padding: '10px 20px 10px 20px', fontWeight: '900' }}>Dashboard</button>
          </NavLink>
        </ul>

      </nav>
    </>
  );
};

export default NavbarMain;
