import Cover from '../../../1_MediaAssets/Homepage/Texture.png';
import Certificate from '../../../1_MediaAssets/MainPages/Certificate.png';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain.js';

const StudentCertificate = () => { 

    return (
        <>
        <Navbar/>
            <div style={{ paddingTop: '50px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', minHeight: '100vh', maxHeight: '100%', flexDirection: 'column'}}>
                <div style={{ marginTop: '10px', border: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90vw', flexDirection: 'column', background: '#9CE3FF', height: '63.6vw', backgroundImage:`url('${Certificate}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                    <h1 style={{fontSize: '4vw', marginBottom: '5vw', color: '#1D5BC0'}}>Ahmed Fayaz Yousuf</h1>
                    <p style={{fontSize: '1.5vw', textAlign: 'center'}}>for successful completion of a Rex approved course,</p>
                    <p style={{fontSize: '1.5vw', textAlign: 'center', marginBottom: '2vw'}}>
                        <span style={{textDecoration: 'underline', fontWeight: '900'}}>Title of the course</span>
                        &nbsp;on&nbsp;
                        <span style={{textDecoration: 'underline', fontWeight: '900'}}>27 May, 2024</span>
                    </p>
                    <p style={{fontSize: '1.5vw',textAlign: 'center'}}>Validated by:<br></br><span style={{fontWeight: '900', color: '#1D5BC0'}}>Dr. Tiffany Delacruz, CEO & Veterinarian | DVM</span></p>
                </div>
            </div>
        </>
        )
}

export default StudentCertificate 