import { initializeApp } from "firebase/app";
// eslint-disable-next-line 
import { getStorage, ref } from "firebase/storage";
// eslint-disable-next-line 
import { getFirestore, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDyLAMdSxGEqeQzPZrCXAM1OVen6ix0u5Q",
  authDomain: "rexmedicalevents.firebaseapp.com",
  projectId: "rexmedicalevents",
  storageBucket: "rexmedicalevents.appspot.com",
  messagingSenderId: "791442350981",
  appId: "1:791442350981:web:d706eebc65fc0f5ca41aec",
  measurementId: "G-ZF1DYF34QQ"
};


const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const db = getFirestore(app);
// eslint-disable-next-line
const auth = getAuth(app);

export { app, db, storage,auth };

