import LogoBlue from '../../1_MediaAssets/Homepage/LogoBlue.png';
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";

const ResourcePage = () => { 
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [item, setItem] = useState(location.state?.item);
    const [loading, setLoading] = useState(!item); // only set loading if item is not already set

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, "Resources", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = { id: docSnap.id, ...docSnap.data() };
                    setItem(data);
                    setLoading(false);
                    document.title = data.Name; // Set the document title here
                } else { 
                    navigate('/Resources');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                navigate('/Resources');
            }
        };

        if (!item) {
            fetchData();
        } else {
            document.title = item.Name; // Set the document title if item is already available
        }
    }, [id, item, db, navigate]);

    if (loading) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
        <img src={LogoBlue} alt='nothingness' style={{width: '100px'}}/>
      </div>;
    }

    const createdAtDate = new Date(item.CreatedAt.seconds * 1000);

    return ( 
        <>  
        <Navbar/>
            <div style={{paddingTop: '50px', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>

                <div className="parallax-item2" style={{display: 'flex', flexDirection: 'column', backgroundImage:`url('${item.Image}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <h1 style={{color: 'white', margin: '0px', textAlign: 'center', width: '95%'}}>{item.Name}</h1>
                    <p style={{borderTop: '1px solid #fff', width: '60%', margin: '10px'}}></p>
                    <p style={{textAlign:'center', marginBottom: '40px', fontWeight: '900', color: '#fff'}}>by {item.Author} | {createdAtDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                </div> 

                <div style={{width: '100%', padding: '15px', background: 'white', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <div className="BlogTextArea">
                        <div dangerouslySetInnerHTML={{ __html: item.Data }} />
                    </div>
                </div>

            </div>
        <Footer />
        </>
    );
}

export default ResourcePage;
