import Hafsa from '../../1_MediaAssets/MainPages/Team/Hafsa.jpg';
import Dua from '../../1_MediaAssets/MainPages/Team/Dua.jpg';
import Ahmed from '../../1_MediaAssets/MainPages/Team/Ahmed.jpg';
import React from 'react';
// import Image from '../../1_MediaAssets/MainPages/Faculty/DrMalgorzata Uchman.jpeg';

const teamMembers = [
    { name: 'Dua Fatima', role: 'Marketing Manager', image: Dua },
    { name: 'Hafsa Rafiq', role: 'Event Coordinator', image: Hafsa },
    { name: 'Ahmed Fayaz', role: 'Software Developer', image: Ahmed },
    // { name: 'Member 2', role: 'Profession', image: Image }
];

const MeetTheTeam = () => {
    return (
        <div className="meet-the-team" style={{background: 'white', width: '100vw'}}>
            <h1 style={{marginBottom: '30px'}}>Meet the <span className='fontSpecial' style={{color: '#1D5BC0'}}>Team</span></h1>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div key={index} className='team-member'>
                        <img src={member.image} alt={member.name} style={{}} />
                        <h2 style={{fontWeight: '900'}}>{member.name}</h2>
                        <p>{member.role}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MeetTheTeam;
