import { MdOutlineUpcoming } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { useEffect, useState } from 'react';
import Cover from '../../1_MediaAssets/Homepage/Texture3Light.png';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, query, getDoc, doc, where } from "firebase/firestore";

import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';

const DashboardStudent = () => {
    const db = getFirestore();
    const auth = getAuth(); 

    const [activeTab, setActiveTab] = useState('Upcoming');
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);

    const toggleTab = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/Login';
            } else {
                const userRegCollection = collection(db, 'Students', auth.currentUser.uid, 'RegisteredCourses');
                // eslint-disable-next-line
                const courseRegCollection = collection(db, 'Course');

                const userRegQuery = query(userRegCollection);
                const data = [];
                // eslint-disable-next-line
                const user = doc(db, 'Students', auth.currentUser.uid);

                getDocs(userRegQuery).then((querySnapshot) => {
                    const promises = querySnapshot.docs.map(async (docc) => {
                        const courseRef = docc.data().courseRef;
                        // const userCollection = doc(db, courseRef, 'RegisteredStudents',auth.currentUser.uid);
                        const col = collection(courseRef, 'RegisteredStudents');
                        const q = query(col, where("userRef", "==", user));
                        var failBool = ''
                        getDocs(q).then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                              console.log(doc.data());
                              failBool = doc.data().status;
                              console.log(failBool);
                            });
                        });

                        // const docu = doc(col,auth.currentUser.uid);
                        // const test = await getDoc(docu);
                        // console.log(test.data(),'testing');
                        return getDoc(courseRef).then((courseDoc) => {
                            if (courseDoc.exists()) {
                                data.push({ ...courseDoc.data(), registeredStudentId: docc.id, status: failBool});
                            }
                        });
                    });

                    Promise.all(promises).then(() => {
                        const currentDate = new Date();
                        const upcoming = data.filter(event => new Date(event.EndDate) >= currentDate);
                        const past = data.filter(event => new Date(event.EndDate) < currentDate);
                        setUpcomingEvents(upcoming);
                        setPastEvents(past);
                    });
                });
            }
        });
    }, [auth, db]);

    useEffect(() => {
        document.title = "Dashboard | REX Medical Events";
    }, []);

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', flexDirection: 'column', background: '#1D5BC0', height: '100%', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',  }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', background: '#9CE3FF' }}>
                        <div
                            style={{ cursor: 'pointer', padding: '10px', width: '50%', textAlign: 'center', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: activeTab === 'Upcoming' ? 'bold' : 'normal', background: activeTab === 'Upcoming' ? '#8FF2CC' : '#ededeb' }}
                            onClick={() => toggleTab('Upcoming')}>
                            <MdOutlineUpcoming style={{ marginRight: '4px' }} />Upcoming
                        </div>
                        <div
                            style={{ cursor: 'pointer', padding: '10px', width: '50%', textAlign: 'center', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: activeTab === 'Past' ? 'bold' : 'normal', background: activeTab === 'Past' ? '#8FF2CC' : '#ededeb' }}
                            onClick={() => toggleTab('Past')}>
                            <FaHistory style={{ marginRight: '4px' }} />Past
                        </div>
                    </div>

                    {activeTab === 'Upcoming' && (
                        upcomingEvents.map((event, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                                <div className='PatientDiv'>
                                    <div className='SubPatientDiv'>
                                        <h1 style={{ fontSize: '14px', padding: '0', margin: '0px 2vw 0px 0px' }}>{event.Name}</h1>
                                        <p style={{ fontSize: '13px' }}>{event.Type} Event</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <p style={{ fontSize: '13px', marginLeft: '2vw' }}>{event.StartDate} - {event.EndDate}<br /> <span style={{ fontSize: '12px', color: 'grey' }}>{event.StartTime} - {event.EndTime}</span></p>
                                    </div>
                                    {event.status === 'Pass' ? 
                                    <button style={{background: '#1D5BC0', fontSize:'12px'}}>Download Certificate</button> 
                                    : null
                                    }                                
                                </div>
                            </div>
                        ))
                    )}

                    {activeTab === 'Past' && (
                        pastEvents.map((event, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                                <div className='PatientDiv'>
                                    <div className='SubPatientDiv'>
                                        <h1 style={{ fontSize: '14px', padding: '0', margin: '0px 2vw 0px 0px' }}>{event.Name}</h1>
                                        <p style={{ fontSize: '13px' }}>{event.Type} Event</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <p style={{ fontSize: '13px', marginLeft: '2vw' }}>{event.StartDate} - {event.EndDate}<br /> <span style={{ fontSize: '12px', color: 'grey' }}>{event.StartTime} - {event.EndTime}</span></p>
                                    </div>
                                    <button style={{background: '#1D5BC0', fontSize:'12px'}}>Download Certificate</button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
}

export default DashboardStudent;
