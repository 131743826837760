import React from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Cover2 from '../../1_MediaAssets/Homepage/Texture3Light.png';
import Cover3 from '../../1_MediaAssets/Homepage/Texture2.png';
import DrMayuri from '../../1_MediaAssets/MainPages/Faculty/DrMayuriKothiwala.png';
import DrSunilKothiwala from '../../1_MediaAssets/MainPages/Faculty/DrSunilKothiwala.jpeg';
import DrMalgorzata from '../../1_MediaAssets/MainPages/Faculty/DrMalgorzata Uchman.jpeg';
import DrSheetal from '../../1_MediaAssets/MainPages/Faculty/Sheetal.jpg';
import DrArtan from '../../1_MediaAssets/MainPages/Faculty/Artan.jpg';
import DrSenera from '../../1_MediaAssets/MainPages/Faculty/Senera.jpeg';
import DrAkhileshwar from '../../1_MediaAssets/MainPages/Faculty/Akhileshwar.png';
import DrTracey from '../../1_MediaAssets/MainPages/Faculty/Tracey.jpg';

const FacultyWidget = () => { 

  const facultyContent = [
    {
        heading: 'Dr. Mayuri Kothiwala',
        text: 'MBBS, MS, FIAGE, DGES (Germany), FCGS, Aesthetic Gynecologist and Laparoscopic Surgeon',
        icon: <img src={DrMayuri} alt="Cosmetic Gynecology" className="faculty-image" />
    },
    {
        heading: 'Dr. Sunil Kothiwala',
        text: 'Dermatologist, Cosmetologist & Hair Transplant Surgeon. Founder of SkinEva Clinic.',
        icon: <img src={DrSunilKothiwala} alt="Aesthetic Medicine" className="faculty-image" />
    },
    {
        heading: 'Dr. Malgorzata Uchman',
        text: 'A passionate gynecologist with a particular interest in feminine genital cosmetic surgery CEO FEMINITY Clinic.',
        icon: <img src={DrMalgorzata} alt="Laparoscopic Surgery" className="faculty-image" />
    },
    {
        heading: 'Dr. Sheetal Sawankar',
        text: 'Founder & Director - Avisa IVF & Fertility Center, Managing Committee Member - Maharashtra State Chapter of ISAR',
        icon: <img src={DrSheetal} alt="Hysteroscopy" className="faculty-image" />
    },
    {
        heading: 'Dr. Artan Koni',
        text: 'Urology, Uropediatrics, Renal Transplant, Impotence and Incontinence Surgery, Endo-urology',
        icon: <img src={DrArtan} alt="Urogynecological" className="faculty-image" />
    },
    {
        heading: 'Dr. Senera Hoxha',
        text: 'Plastic Surgeon Skilled in Dermal, Fillers, Botox Cosmetic, Breast Surgery, Medicine, and Tummy Tuck.',
        icon: <img src={DrSenera} alt="Urogynecological" className="faculty-image" />
    },
    {
        heading: 'Dr. Akhileshwar Singh',
        text: 'Infertility Consultant and Gynaecologic Endoscopic Surgeon, Obstetrician & Gynaecologist',
        icon: <img src={DrAkhileshwar} alt="Urogynecological" className="faculty-image" />
    },
    {
        heading: 'Dr. Tracey Sainsbury',
        text: 'Senior Fertility Counsellor. Accredited with the British Infertility Counselling Association.',
        icon: <img src={DrTracey} alt="Urogynecological" className="faculty-image" />
    }
  ];

  return (
    <div style={{backgroundImage:`url('${Cover3}')`, padding: '50px 30px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', flexDirection: 'column'}}>
      <h1 style={{ width: '100vw', padding: '0', fontWeight: '900', textAlign: 'center', marginBottom: '10px' }}>Our <span className='fontSpecial' style={{color: '#1D5BC0'}}>Esteemed Faculty</span></h1>
      <p style={{textAlign: 'center', width: '92vw', fontSize: '14px', padding: '5px 0px 10px 0px', maxWidth: '1200px'}}>The faculty at Rex Medical Events consists of outstanding international professionals who are widely recognized for their significant practical experience and tireless commitment to medical education. Every instructor possesses an extensive level of expertise and specialized skills, guaranteeing that our courses provide the most up-to-date insights and thorough instruction. Our teaching faculty is committed to creating a stimulating learning environment that enables healthcare professionals to achieve excellence in their respective disciplines. </p>
      <Splide style={{width: '100vw'}}
        options={{
          perPage: 5,
          perMove: 1,
          breakpoints: {
            1200: { perPage: 2 },
            800: { perPage: 1 },
          },
          gap: '1rem',
          pagination: false,
          arrows: true,
          autoplay: true,
        }}
      >
        {facultyContent.map((course, index) => (
          <SplideSlide key={index} style={{padding: '10px 0px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="faculty-card" style={{backgroundColor:'#8FF2CC', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center', borderRadius: '10px'}}>
              {course.icon}
              <h2 className='fontSpecial' style={{fontSize: '16px', marginTop: '10px', color: '#1D5BC0'}}>{course.heading}</h2>
              <p style={{fontSize: '12px', width: '100%', color: 'black'}}>{course.text}</p>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default FacultyWidget;
