import Wallpaper from '../../../1_MediaAssets/MainPages/Whoa.png' 
import Texture from '../../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain.js';
import { NavLink } from 'react-router-dom';
// eslint-disable-next-line
import { doc, getDoc, query, where, collection, getFirestore, addDoc, getDocs } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, onAuthStateChanged,setPersistence } from "firebase/auth";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Login = () => { 
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const navigate = useNavigate();
    
    const courseID = location.state?.courseID ?? null;
    const amount = location.state?.amount ?? null;

    useEffect(() => {
        if(location.state){
            console.log(location.state.message);
            const messageElement = document.getElementById('message');
            if (messageElement) {
                messageElement.innerHTML = location.state.message + '!';
            }
        }
    }, [location.state]);

    useEffect(() => {
        document.title = "Sign in | REX Medical Events";
    }, []);


    const handleSubmit = async () => {

        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        console.log(email)

        if(email === '' || password === ''){
            document.getElementById('message').innerText = 'Please fill in all fields';   
            return;
        }

        signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const user = userCredential.user;
            localStorage.setItem('userId', user.uid);

            const docRef = doc(db, "Students", user.uid);

            const docSnap = await getDoc(docRef);
            console.log(docSnap.data());

            if(docSnap.data().UserType === 'admin'){
                navigate('/SysAdmin', { state: { admin: docSnap.data() } })
            } else{
                // navigate('/DashboardStudent', { state: { student: docSnap.data() } })
                if (!courseID || !amount) {
                    navigate('/UpcomingCourses', { state: { student: docSnap.data() } })
                } else {
                    navigate('/checkout', { state: { courseID: courseID, amount: amount } });
                }
            }


          })
          .catch((error) => {
            // eslint-disable-next-line 
            const errorCode = error.code;
            // eslint-disable-next-line 
            const errorMessage = error.message;
            console.log(errorMessage)

            if(errorMessage === 'Firebase: Error (auth/invalid-login-credentials).'){
                document.getElementById('message').innerHTML = 'Invalid Email or Password!';
                document.getElementById('message').style.color = 'red';
            }
            if(errorMessage === undefined){
                document.getElementById('message').innerHTML = '';
            }
          });
    };

    
    useEffect(() => {

        const checkAuthAndSetPersistence = async () => {
            const user = auth.currentUser;
            if (user) {

                try {
                    await setPersistence(auth, browserLocalPersistence);
                    // Listen for auth state changes
                    onAuthStateChanged(auth, async (user) => {
                        if (user) {
                            // User is signed in, navigate to the dashboard
                            const userId = localStorage.getItem('userId');
                            const docRef = doc(db, "Students", userId);
    
                            const docSnap = await getDoc(docRef);
                            console.log("Document data:", docSnap.data());
                            if(docSnap.data().UserType === 'admin'){
                                navigate('/SysAdmin', { state: { user: docSnap.data() } });
                                return;
                            }
                            navigate('/DashboardStudent', { state: { user: docSnap.data() } });
                            // setParentData(docSnap.data());
                        }
                    });
                } catch (error) {
                    // Handle Errors here.
                    // eslint-disable-next-line
                    var errorCode = error.code;
                    // eslint-disable-next-line
                    var errorMessage = error.message;
                }
            }
        };
    
        checkAuthAndSetPersistence();
        // eslint-disable-next-line
    }, [auth]);

    return (
        <>
        <Navbar />
            <div style={{paddingTop: '50px', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Wallpaper}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px', boxShadow: '0 4px 16px rgba(0, 0, 0, 0.3)'}}>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column'}}>
                        <h1 style={{fontWeight: '900', color: '#1D5BC0', fontSize: '30px'}}>Welcome Back!</h1>
                        <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Sign in to the dashboard or&nbsp;
                        <NavLink to='/RegistrationStudents'>click here
                        </NavLink>
                        &nbsp;to register</h5>
                    </div>

                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px'}}>
                        <div className='optionBox' style={{marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}> 
                            <div className='OnlyColumnPage2' style={{padding: '0px 30px 0px 30px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Email</label>
                                    <input type="text" id='email' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Password</label>
                                    <input type="password" id='password' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                                <NavLink to='/ForgotPassword'> 
                                    <p style={{marginTop:"10px",textDecoration:'underline', color:'gray',cursor:'pointer', fontSize: '13px', textAlign: 'left'}}>Forgot Password</p>
                                </NavLink>
                                <p style={{fontSize: '13px'}}>Don't have an account? 
                                    <NavLink to='/RegistrationStudents'> 
                                        <span style={{cursor:'pointer', marginLeft: '5px'}}>Register</span>
                                    </NavLink>
                                </p>
                                <div id='message' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px', marginTop:"10px", color:'green', fontSize: '13px', width: '100%', textAlign: 'center'}}></div>
                            </div> 
                        </div>

                        <div className='ButtonofWhiteBox' style={{marginTop: '-10px'}}>
                            <button style={{width: '200px'}} onClick={handleSubmit}>Sign in</button>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
        )
}

export default Login