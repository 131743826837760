import React, { useRef } from 'react';
import Cover from '../1_MediaAssets/Homepage/Texture.png';
// import Cover2 from '../1_MediaAssets/MainPages/Whoa.png';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const Success = () => { 
    const location = useLocation();
    const userID = location.state?.userID;
    const transactionID = location.state?.transactionID;
    const ScreenshotRef = useRef(null);

    const saveAsPDF = () => {
        const captureElementRef = ScreenshotRef;
    
        if (captureElementRef && captureElementRef.current) {
            const width = captureElementRef.current.offsetWidth;
            const height = captureElementRef.current.offsetHeight;
    
            html2canvas(captureElementRef.current, { width, height }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', [width, height]);
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                pdf.save('EntryPassandPaymentReference_RexMedicalEvents.pdf');
            }).then(() => {
                // Redirect to "/"
                window.location.href = "/";
            });
        }
    };

    return (
        <div style={{ backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundColor: '#8FF2CC', backgroundAttachment: 'fixed', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column' }}>
            <div ref={ScreenshotRef} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', padding: '30px', flexDirection: 'column', width: '400px', borderRadius: '15px',  backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                
                <h3 style={{ margin: '20px', fontWeight: '900', fontSize: '24px', textAlign: 'center', color: '#333' }}>Payment Successful!</h3>
                <QRCode value={userID} size={200} />
                <h3 style={{ margin: '20px', fontWeight: '900', fontSize: '18px', textAlign: 'center', color: '#333' }}>Show this pass at the reception on event day!</h3>
                {transactionID && <p style={{ margin: '10px', fontSize: '16px', textAlign: 'center', color: '#555' }}>Transaction ID: {transactionID}</p> }
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <button onClick={saveAsPDF} style={{ width: '100%', borderRadius: '10px', background: '#8FF2CC', color: '#333', border: 'none', padding: '10px', cursor: 'pointer', marginTop: '20px', marginRight: '10px' }}>Save as PDF</button>
                </div>
            </div>
        </div>
    );
};

export default Success;
