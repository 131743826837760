import React from 'react';
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import CoverMain from '../../1_MediaAssets/MainPages/PolicyPages/tandcCover.jpg';

import Navbar from '../../Navbars/NavbarMain/NavbarMain.js'; 
import Footer from '../../Navbars/Footer';
import { useEffect } from 'react';

const TermsandConditions = () => { 

    useEffect(() => {
        document.title = "Terms & Conditions | REX Medical Events";
    }, []);


    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column'}}>
                <div className="parallax-item3" style={{backgroundImage: `url('${CoverMain}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <h1 style={{color: 'white'}}>Terms & Conditions</h1>
                </div> 
            </div>
            <div style={{width: '100%', padding: '30px', background: 'white', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="BlogTextArea2">
                    <p>Welcome to REX Medical Events! These terms and conditions outline the rules and regulations for the use of REX Medical Events' Website, located at <a href='/' target='blank' style={{textDecoration: 'none'}}>www.rexmedicalevents.com</a>. By accessing this website, we assume you accept these terms and conditions. Do not continue to use REX Medical Events if you do not agree to take all of the terms and conditions stated on this page.</p>
                    <p style={{marginBottom: '20px'}}>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client," "You," and "Your" refer to you, the person logging onto this website and compliant with the Company’s terms and conditions...</p>
                    <h2>Cookies</h2>
                    <p>We employ the use of cookies. By accessing REX Medical Events, you agree to use cookies in agreement with REX Medical Events’ Privacy Policy. Most interactive websites use cookies to let us retrieve the user’s details for each visit...</p>
                    <h2 style={{marginTop: '20px'}}>License</h2>
                    <p>Unless otherwise stated, REX Medical Events and/or its licensors own the intellectual property rights for all material on REX Medical Events. All intellectual property rights are reserved...</p>
                    <p>You must not:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Republish material from REX Medical Events</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Sell, rent, or sub-license material from REX Medical Events</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Reproduce, duplicate, or copy material from REX Medical Events</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Redistribute content from REX Medical Events</p>
                    <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. REX Medical Events does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of REX Medical Events, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, REX Medical Events shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                    <p>REX Medical Events reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes a breach of these Terms and Conditions.</p>
                    <p>You warrant and represent that:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy;</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</p>
                    <p>You hereby grant REX Medical Events a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any of your Comments in any and all forms, formats, or media.</p>
                    <h2 style={{marginTop: '20px'}}>Hyperlinking to our Content</h2>
                    <p>The following organizations may link to our Website without prior written approval:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Government agencies</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Search engines</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	News organizations</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses</p>
                    <p>These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>
                    <p>We may consider and approve other link requests from the following types of organizations:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Commonly-known consumer and/or business information sources</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Dot.com community sites</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Associations or other groups representing charities</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Online directory distributors</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Internet portals</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Accounting, law, and consulting firms</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Educational institutions and trade associations</p>
                    <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of REX Medical Events; and (d) the link is in the context of general resource information.</p>
                    <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
                    <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to REX Medical Events. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
                    <p>Approved organizations may hyperlink to our Website as follows:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	By use of our corporate name</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	By use of the uniform resource locator being linked to</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site</p>
                    <p>No use of REX Medical Events’ logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                    <h2 style={{marginTop: '20px'}}>iFrames</h2>
                    <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
                    <h2 style={{marginTop: '20px'}}>Content Liability</h2>
                    <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                    <h2 style={{marginTop: '20px'}}>Reservation of Rights</h2>
                    <p>We reserve the right to request that you remove all links or any particular link to our Website. You agree to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                    <h2 style={{marginTop: '20px'}}>Removal of links from our website</h2>
                    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links.</p>

                
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsandConditions;
