import Cover from '../../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {useEffect, useState} from 'react';
import { getFirestore, collection, addDoc, serverTimestamp, doc,getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { storage } from '../../../../firebase/index.js';
import { useRef } from 'react';


const CreateResource = () => { 
    const [image, setImage] = useState(null);
    // eslint-disable-next-line
    const [imageFile, setImageFile] = useState(null);
    // eslint-disable-next-line
    const [content, setContent] = useState('');
    const db = getFirestore();
    // const storage = getStorage();
    // eslint-disable-next-line 
    const auth = getAuth();
    const imageRef = useRef(null);

     
    const handleSubmit = () => {
        const name = document.getElementById('name').value;
        const author = document.getElementById('author').value;
        const data = content;
        const image = imageRef.current.files[0];
    
        const errorElement = document.getElementById('error');
    
        if (!name || !author || !data || !image || !errorElement) {
            if (errorElement) {
                errorElement.textContent = 'Please fill out all fields and upload a cover image.';
            }
            return;
        }
    
        document.getElementById('ButtonText').textContent = 'Loading...';
    
        const storageRef = ref(storage, `resources/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
        }, (error) => {
            console.error(error);
        }, async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const docRef = await addDoc(collection(db, 'Resources'), {
                Name: name,
                Author: author,
                Data: data,
                Image: downloadURL,
                CreatedAt: serverTimestamp()
            });
            console.log('Document written with ID: ', docRef.id);
    
            document.getElementById('name').value = '';
            document.getElementById('author').value = '';
            document.getElementById('image').value = '';
            setContent('');
            document.getElementById('error').style.color = 'green';
            document.getElementById('error').textContent = 'Event posted successfully!';
            document.getElementById('ButtonText').textContent = 'Post';
            window.location.reload();
        });
    }
    

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0]);
            setImageFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onload = (event) => {
                setImage(event.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }

        console.log(image);
    };
 
    useEffect(()=>{

        onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/Login';
            } else {
                // eslint-disable-next-line
                const data = [];
                const user = doc(db, 'Students', auth.currentUser.uid);

                getDoc(user).then((doc) => {
                    const data = doc.data();

                    if(data.UserType === 'admin'){
                        return;
                    }
                    else{
                        window.location.href = '/DashboardStudent';
                    }
                });
            }
        });
        const initializeQuill = (selector, stateKey) => {
            const quill = new Quill(selector, {
                theme: 'snow'
            });
            quill.on('text-change', () => {
                const content = quill.root.innerHTML;
                setContent(content);

            });
        };
        initializeQuill('#editor', 'data');
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        document.title = "Create Resource | Admin";
    }, []);

    return ( 
        <> 
        <Navbar/>
        <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
            <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px 30px 0px 30px', flexDirection: 'column'}}>
                    <h1 style={{fontWeight: '900', fontSize: '30px', color: 'black'}}>Create resource</h1>
                </div>

                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px'}}>
                    <div className='optionBox' style={{marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px'}}> 
                        <div className='OnlyColumnPage2' style={{padding: '0px 30px 0px 30px'}}>
                            
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ width: '100%', height: '200px', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightgrey', color: 'black', fontWeight: '900', borderRadius: '10px', position: 'relative', overflow: 'hidden' }} >{image ? (
                                    <img src={image} alt='Course Cover' style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} />
                                    ) : (
                                        <span>Upload cover</span>
                                    )}
                                </div>
                                <label style={{ fontWeight: '900', fontSize: '13px', marginTop: '10px'}}>Resource cover</label>
                                <input type='file' id='image'  ref={imageRef} accept='image/*' style={{fontSize: '12px'}} onChange={handleImageChange} />
                            </div>
                            

                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px'}}>Resource title</label>
                                <input type="text" id='name' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px'}}>Author</label>
                                <input type="text" id='author' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                <div id="editor" className="my-quill-editor" style={{width:'100%',height:'20%',display:'flex',flexDirection:'column',border:'1px solid black'}}>
                                </div>
                            </div>

                            <div style={{display: 'flex',justifyContent:'center',alignItems:'center', flexDirection: 'column', width: '100%', marginTop: '10px', height: '30px'}}>
                                <label style={{fontWeight: '900', fontSize: '13px', color:'red', padding: '0', margin: '0'}} id='error'></label>
                            </div>
                        </div> 
                    </div>

                    <div className='ButtonofWhiteBox' style={{ marginTop: '-15px'}}>
                        <button id='ButtonText' style={{width: '200px', background: 'black'}} onClick={handleSubmit}>Post</button>
                    </div>
                </div>

            </div>
        </div>
        </>
        )
}

export default CreateResource;