import Cover from '../../../1_MediaAssets/Homepage/Texture3.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
import { getFirestore, collection, doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const CourseRegistrationsList = () => {
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation(); 
    const [registeredUsers, setRegisteredUsers] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/Login';
            } else {
                const userRef = doc(db, 'Students', auth.currentUser.uid);

                getDoc(userRef).then((doc) => {
                    const data = doc.data();

                    if (data.UserType === 'admin') {
                        return;
                    } else {
                        window.location.href = '/DashboardStudent';
                    }
                });
            }
        });

        return () => unsubscribe();
    }, [auth, db]);

    useEffect(() => {
        const courseRef = doc(db, 'Courses', location.state.course);
        const usersCollection = collection(courseRef, 'RegisteredStudents');

        const unsubscribe = onSnapshot(usersCollection, async (snapshot) => {
            const data = [];
            const promises = snapshot.docs.map(async (doc) => {
                const userRef = doc.data().userRef;
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const transaction = userDoc.data().Transactions.find(transaction => transaction.courseID === location.state.course);
                    data.push({ 
                        ...userDoc.data(), 
                        id: userDoc.id, 
                        registeredStudentId: doc.id, 
                        status: doc.data().status || "-", 
                        amount: transaction.amount 
                    });
                }
            });

            await Promise.all(promises);
            setRegisteredUsers(data);
        }); 

        return () => unsubscribe();
    }, [db, location.state.course]);

    async function Pass(user) {
        await updateDoc(doc(db, 'Courses', location.state.course, 'RegisteredStudents', user.registeredStudentId), {
            status: 'Pass'
        });
    }

    async function Fail(user) {
        await updateDoc(doc(db, 'Courses', location.state.course, 'RegisteredStudents', user.registeredStudentId), {
            status: 'Fail'
        });
    }

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column' }}>
                <h1 style={{ padding: '0', margin: '30px 0px 10px 0px', fontWeight: '900' }}>Grade Students</h1>
                <div style={{ width: '100%', overflow: 'auto', padding: '-5px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <table style={{ width: '97%', borderCollapse: 'collapse', marginTop: '20px' }}>
                        <thead>
                            <tr style={{ background: 'white', color: 'white', fontWeight: '900', textAlign: 'center', borderRadius: '15px' }}>
                                <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '15px 0px 0px 0px', fontWeight: '300' }}>S#</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Name</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Email Address</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Amount Paid</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Current Grade</th>
                                <th style={{ padding: '8px', fontSize: '14px', background: '#2a2f36', borderRadius: '0px 15px 0px 0px', fontWeight: '300' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registeredUsers.map((user, index) => (
                                <tr key={index} style={{ background: index % 2 === 0 ? '#dcdee0' : '#f7f7f7' }}>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center', background: '#2a2f36', color: 'white', fontWeight: '900' }}>{index + 1}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>{user.Name}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>{user.EmailAddress}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>${user.amount}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>{user.status}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                        <button onClick={() => Pass(user)} style={{ width: '50px', fontSize: '12px', background: 'lightgreen', color: 'black' }}>Pass</button>
                                        <button onClick={() => Fail(user)} style={{ width: '50px', fontSize: '12px', background: '#ffc2c2', color: 'black' }}>Fail</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default CourseRegistrationsList;
