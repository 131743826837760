import BlogSection from '../6_Resources/ResourceWidget.js';
import React from 'react';
import Faculty from '../4_Faculty/FacultyWidget.js';
import UpcomingCourses from '../5_UpcomingCourses/UpcomingCoursesWidget.js';
import { FaChalkboardTeacher } from "react-icons/fa"; import { FaHandshake } from "react-icons/fa6";import { LiaChalkboardTeacherSolid } from "react-icons/lia";import { PiCertificateBold } from "react-icons/pi";import { TbWorldHeart } from "react-icons/tb";
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import HeroImage from '../../1_MediaAssets/Homepage/Images/HeroImage.jpg';
import Footer from '../../Navbars/Footer/index.js';
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Cover2 from '../../1_MediaAssets/Homepage/Texture3Light.png';
import Cover3 from '../../1_MediaAssets/Homepage/Texture2.png';
import Carousel from './HomeComponents/CarouselTestimonials.js';
import CarouselAffiliations from './HomeComponents/CarouselAffiliations.js';
import { NavLink } from 'react-router-dom';
import Cosmetic from '../../1_MediaAssets/MainPages/CourseTypePhotos/Cosmetic.jpg'
import Aesthetic from '../../1_MediaAssets/MainPages/CourseTypePhotos/Aesthetic.jpg'
import IVF from '../../1_MediaAssets/MainPages/CourseTypePhotos/IVF.jpg'
import Laparoscopic from '../../1_MediaAssets/MainPages/CourseTypePhotos/Laparoscopiy.jpg'
import Hysteroscopy from '../../1_MediaAssets/MainPages/CourseTypePhotos/Hysteroscopy.jpg'
import Urogynecological from '../../1_MediaAssets/MainPages/CourseTypePhotos/Urogynecology.jpg';
import HeroCoursesCarousel from './HomeComponents/CarouselHero.js';
import { useEffect } from 'react';

const Home = () => {

    useEffect(() => {
        document.title = "REX Medical Events";
    }, []);


    const courseContent = [
        {
            heading: 'Cosmetic Gynecology Courses',
            text: 'Discover the revolutionary field of Cosmetic Gynecology. Our courses are specifically designed to provide you with advanced expertise in both the cosmetic and functional aspects of gynecological treatments. Gain knowledge from experts in the field on procedures such as labiaplasty, vaginal rejuvenation, and non-surgical modifications, enabling you to provide your patients with exceptional care and aesthetic contentment. If you desire to enhance your skills or focus on this emerging area, our Cosmetic Gynecology Courses offer extensive instruction to help you achieve your goal.',
            icon: <img src={Cosmetic} alt="Cosmetic Gynecology" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }} />
        },
        {
            heading: 'Aesthetic Medicine Courses',
            text: 'Join our Aesthetic Medicine Courses and acquire expertise in the rapidly advancing domain of cosmetic treatments. Our courses encompass a wide range of non-surgical cosmetic procedures, including Botox, dermal fillers, laser therapy, and skin rejuvenation techniques. Under the guidance of highly qualified professionals, you will acquire practical experience and extensive expertise to enable you to administer secure, efficient, and innovative therapies to your patients. Enhance your skills and maintain a competitive edge in the field of cosmetic medicine with our meticulously designed programs.',
            icon: <img src={Aesthetic} alt="Aesthetic Medicine" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }}  />
        },
        {
            heading: 'Laparoscopic Surgery Courses',
            text: 'Improve your surgical skills by participating in our Laparoscopic Surgery Courses. Our training programs are designed especially for surgeons who wish to achieve mastery in minimally invasive methods. Acquire knowledge about the most recent developments in laparoscopic operations from the experts in the field, while also obtaining practical experience using modern technology. Our courses cater to both beginner and experienced surgeons, offering the opportunity to enhance patient outcomes, minimize recovery periods, and stay up-to-date with modern surgical advancements.',
            icon: <img src={Laparoscopic} alt="Laparoscopic Surgery" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }}  />
        },
        {
            heading: 'IVF & Reproductive Medicine Courses',
            text: 'Our IVF and Reproductive Medicine Courses will help you learn more about fertility treatments. In vitro fertilization (IVF) and advanced reproductive endocrinology are just a few of the areas that our specialized training programs cover. You will learn a lot of in-depth information and useful skills from well-known fertility experts that will help you improve your practice and increase the success rates of your patients. ',
            icon: <img src={IVF} alt="IVF and Reproductive Medicine" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }}  />
        },
        {
            heading: 'Hysteroscopy Courses',
            text: 'Master the art of hysteroscopy with our courses. These courses teach you everything you need to know about both diagnostic and surgical hysteroscopy, from the most basic methods to the most complex ones. Learn how to use the newest tools and learn from professionals with a lot of experience. Our courses are designed to enhance your skills, improve patient care, and keep you updated on the latest advancements in hysteroscopic surgery.',
            icon: <img src={Hysteroscopy} alt="Hysteroscopy" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }}  />
        },
        {
            heading: 'Urogynecological Courses',
            text: 'Join our Urogynecological Courses to gain expertise in the treatment of pelvic floor disorders. Our extensive training programs encompass the diagnosis and treatment of conditions such as urine incontinence, pelvic organ prolapse, and other urogynecological concerns. Acquire knowledge from leading experts in the field and obtain practical experience through interactive training. The purpose of our courses is to equip you with the expertise and understanding required to deliver optimal care in the field of urogynecology to your patients.',
            icon: <img src={Urogynecological} alt="Urogynecological" style={{ width: '100%', color: '#1D5BC0', borderRadius: '10px', marginBottom: '3px' }}  />
        }
    ];

    

    return (
        <>  
            <Navbar />
            <HeroCoursesCarousel />
            <div className="HomeHeroMainDiv" style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', paddingTop: '0px'}}>
                <div className="HeroMainDivImage DPhider">
                    <img src={HeroImage} alt='HeroImage' style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}></img>
                </div>

                <div className="HeroMainDivText_Pets" style={{margin: '30px'}} >
                    <TextAnim Body={ 
                        <h1 style={{ marginBottom: '10px' }}>Your Gateway to <span className='fontSpecial' style={{color: '#1D5BC0'}}>Excellence</span> in Medical Training</h1>
                    } />
                    <TextAnim Body={
                        <p style={{ marginBottom: '30px', marginTop: '10px' }}>REX Medical Events is your trusted partner in medical education, offering a comprehensive range of premier training courses and certification programs. We provide healthcare professionals with the equipment and knowledge they need to do well in their jobs because we are dedicated to excellence. Our superior curriculum is created to give both aspiring and experienced practitioners the skills they need to stay ahead in the constantly changing field of medicine.</p>
                    } />
                    <TextAnim Body={
                        <NavLink to='/UpcomingCourses'>
                            <button style={{ width: '200px', marginBottom: '30px', background: '#1D5BC0', color: 'white' }}>Upcoming Courses</button>
                        </NavLink>
                    } />
                </div> 
            </div>
            
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', backgroundImage:`url('${Cover3}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                hello
            </div> */}

            {/* TRINITY STRIP  */}
            <div style={{width: '100vw', backgroundColor:'white', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage:`url('${Cover3}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="TrinityStripHome">
                    <div className="TrinityStripHomeElement" style={{backgroundColor:'#1D5BC0', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                        <TbWorldHeart style={{fontSize: '25px', color: '#8FF2CC'}}/>
                        <h1 style={{fontSize: '16px', marginTop: '10px', color: '#8FF2CC'}}>Comprehensive Courses and Hands-On Training</h1>
                        <p style={{fontSize: '12px', width: '90%', color: 'white'}}>We offer courses, advanced workshops, and hands-on training in specialties like Laparoscopy, Hysteroscopy, and Gynecology. Our programs are designed to give doctors, surgeons, and other medical professionals the best education and practical experience.</p>
                    </div>
                    <div className="TrinityStripHomeElement" style={{backgroundColor:'#1D5BC0', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                        <PiCertificateBold style={{fontSize: '25px', color: '#8FF2CC'}}/>
                        <h1 style={{fontSize: '16px', marginTop: '10px', color: '#8FF2CC'}}>Internationally Recognized Certifications</h1>
                        <p style={{fontSize: '12px', width: '90%', color: 'white'}}>Our training courses are designed to not only increase your knowledge but also to provide you with internationally recognized certificates. These certifications are evidence of your dedication and expertise, helping to advance your career in the medical field.</p>
                    </div>
                    <div className="TrinityStripHomeElement" style={{backgroundColor:'#1D5BC0', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                        <FaChalkboardTeacher style={{fontSize: '25px', color: '#8FF2CC'}}/>
                        <h1 style={{fontSize: '16px', marginTop: '10px', color: '#8FF2CC'}}>Innovative Conferences </h1>
                        <p style={{fontSize: '12px', width: '90%', color: 'white'}}>REX Medical Events also hosts conferences on a variety of the latest topics, such as Ultrasound, Urogynaecology, and Aesthetic Medicine. These events are designed to keep you up-to-date about the latest techniques and innovations, providing a platform for sharing knowledge and professional growth.</p>
                    </div>
                    <div className="TrinityStripHomeElement" style={{backgroundColor:'#1D5BC0', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                        <LiaChalkboardTeacherSolid style={{fontSize: '28px', color: '#8FF2CC'}}/>
                        <h1 style={{fontSize: '16px', marginTop: '10px', color: '#8FF2CC'}}>Experienced Faculty</h1>
                        <p style={{fontSize: '12px', width: '90%', color: 'white'}}>The experts who teach our courses are leaders in their fields, helping to shape the future of medicine. Take advantage of their years of experience and knowledge to get the best education and guidance.</p>
                    </div>
                    <div className="TrinityStripHomeElement" style={{backgroundColor:'#1D5BC0', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                        <FaHandshake style={{fontSize: '25px', color: '#8FF2CC'}}/>
                        <h1 style={{fontSize: '16px', marginTop: '10px', color: '#8FF2CC'}}>Join Us for Excellence</h1>
                        <p style={{fontSize: '12px', width: '90%', color: 'white'}}>The experts who teach our courses are leaders in their fields, helping to shape the future of medicine. Take advantage of their years of experience and knowledge to get the best education and guidance.</p>
                    </div>
                </div>
            </div>
 
            <UpcomingCourses />

            {/* COURSE SECTION  */}
            <div style={{backgroundImage:`url('${Cover3}')`, padding: '50px 5px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#1D5BC0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column'}}>
                <div className="course-section">
                    {courseContent.map((course, index) => (
                        <div key={index} className="course-card" style={{backgroundColor:'#8FF2CC', backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center'}}>
                            {course.icon}
                            <h1 style={{fontSize: '16px', marginTop: '10px', color: '#1D5BC0'}}>{course.heading}</h1>
                            <p style={{fontSize: '12px', width: '100%', color: 'black'}}>{course.text}</p>
                        </div>
                    ))}
                </div>
            </div>

            
            <Faculty />
            <BlogSection />
            <CarouselAffiliations />
            <Carousel />
            <Footer />
        </>
    );
};

export default Home;
