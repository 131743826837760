import Cover from '../../../1_MediaAssets/Homepage/Texture3.png'
import React from "react";
import { MDBCarousel, MDBCarouselItem, MDBCol, MDBIcon, MDBContainer, MDBRow, } from "mdb-react-ui-kit";
import DrCameron from '../../../1_MediaAssets/MainPages/Testimonials/DrCameron.jpg';
import DrEmily from '../../../1_MediaAssets/MainPages/Testimonials/DrEmily.jpg';
import DrFannie from '../../../1_MediaAssets/MainPages/Testimonials/DrFannie.jpg';
import DrMaria from '../../../1_MediaAssets/MainPages/Testimonials/DrMariaRod.jpg';
import DrMohammad from '../../../1_MediaAssets/MainPages/Testimonials/DrMohammad.jpg';
import DrThomas from '../../../1_MediaAssets/MainPages/Testimonials/DrThomasSmith.jpg';

export default function App() {
  return (
    <MDBContainer className="py-5" fluid style={{background: 'white', backgroundImage:`url('${Cover}')`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
      <MDBCarousel showControls dark>
        
          <MDBCarouselItem className="active">
            <MDBContainer>
              <MDBRow className="text-center">
                <MDBCol lg="3" className="mb-5 mb-md-0">
                  <div className="d-flex justify-content-center mb-4">
                      <img src={DrThomas} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Dr. Thomas Smith</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    I've attended multiple conferences and training programs, but none compare to Rex Medical Events. The organization's commitment to excellence shines through in every aspect of their courses. I'm grateful for the opportunity to learn from leaders in the field and expand my professional horizons.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="d-none d-lg-block">
                  <div className="d-flex justify-content-center mb-4">                
                      <img src={DrMaria} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Dr. Maria Rodriguez</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Attending the Pulmonology and Respiratory course at Rex Medical Events was a turning point in my career. The course content was relevant and up-to-date, and the faculty's expertise was unparalleled. I left feeling inspired and motivated to implement new techniques in my practice.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="d-none d-lg-block">
                  <div className="d-flex justify-content-center mb-4">                
                      <img src={DrEmily} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Dr. Emily Nguyen</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Attending Rex Medical Events was truly a game-changer for me. The hands-on training and guidance from top-notch instructors in the field helped me refine my surgical skills. I left feeling more confident and capable in my practice than ever before.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="mb-5 mb-md-0">
                  <div className="d-flex justify-content-center mb-4">
                    <img src={DrCameron} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Cameron Williamson</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    As an international attendee, I was impressed by the caliber of faculty and the depth of knowledge shared at Rex Medical Events. The networking opportunities were invaluable, and I returned home with a renewed passion for advancing healthcare in my community.
                  </p>
                  
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCarouselItem>

          <MDBCarouselItem>
            <MDBContainer>
              <MDBRow className="text-center">
                <MDBCol lg="3" className="mb-5 mb-md-0">
                  <div className="d-flex justify-content-center mb-4">
                      <img src={DrFannie} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Fannie Summers</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    I can't recommend Rex Medical Events enough! The MasterClass on Hysteroscopy exceeded my expectations. The interactive sessions and real-world case studies provided a comprehensive learning experience. I feel equipped to tackle even the most challenging cases in my practice now.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="d-none d-lg-block">
                  <div className="d-flex justify-content-center mb-4">                
                      <img src={DrMohammad} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Dr. Mohammad Khan</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Rex Medical Events offers a unique blend of theoretical knowledge and practical skills that are essential for any medical professional. The CTG Master Class was intense and rewarding, and I appreciate the personalized attention I received from the faculty.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="d-none d-lg-block">
                  <div className="d-flex justify-content-center mb-4">                
                      <img src={DrEmily} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Dr. Emily Nguyen</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Attending Rex Medical Events was truly a game-changer for me. The hands-on training and guidance from top-notch instructors in the field helped me refine my surgical skills. I left feeling more confident and capable in my practice than ever before.
                  </p>
                </MDBCol>
                <MDBCol lg="3" className="mb-5 mb-md-0">
                  <div className="d-flex justify-content-center mb-4">
                    <img src={DrCameron} className="rounded-circle shadow-1-strong" alt="gigi" width="80" height="80" />
                  </div>
                  <h1 className="mb-3" style={{fontWeight: '900', fontSize: '14px', color: '#1D5BC0'}}>Cameron Williamson</h1>
                  <h6 className="mb-3" style={{marginTop: '-15px', fontSize: '12px', fontWeight: '900', color: '#6b6868'}}>Doctor</h6>
                  <p style={{fontSize: '12px'}} className="px-xl-3">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    As an international attendee, I was impressed by the caliber of faculty and the depth of knowledge shared at Rex Medical Events. The networking opportunities were invaluable, and I returned home with a renewed passion for advancing healthcare in my community.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCarouselItem>



      </MDBCarousel>
    </MDBContainer>
  );
}