import { IoAddOutline } from "react-icons/io5";
import Cover from '../../../1_MediaAssets/Homepage/Texture3.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LuListTodo } from "react-icons/lu";
import { RiContactsBook3Line } from "react-icons/ri";
import { MdDelete } from 'react-icons/md';

const CourseOpsList = () => {
    const [showChangesSaved, setShowChangesSaved] = useState(false);
    const db = getFirestore();
    const auth = getAuth();
    const location = useLocation();
    const [toDoList, setToDoList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [activeTab, setActiveTab] = useState('ToDoList');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/Login';
            } else {
                const userRef = doc(db, 'Students', auth.currentUser.uid);
                getDoc(userRef).then((doc) => {
                    const data = doc.data();
                    if (data.UserType === 'admin') {
                        return;
                    } else {
                        window.location.href = '/DashboardStudent';
                    }
                });
            }
        });

        return () => unsubscribe();
    }, [auth, db]);

    useEffect(() => {
        const courseRef = doc(db, 'Courses', location.state.course);

        const fetchToDoList = async () => {
            const courseDoc = await getDoc(courseRef);
            if (courseDoc.exists()) {
                const data = courseDoc.data();
                if (data.ToDoList) {
                    setToDoList(data.ToDoList);
                }
                if (data.ContactList) {
                    setContactList(data.ContactList);
                }
            }
        };

        fetchToDoList();
    }, [db, location.state.course]);

    const handleAddRow = (list, setList, rowType) => {
        const newRow = rowType === 'ToDoList' ? { task: '', deadline: '', remarks: '' } : { title: '', number: '' };
        setList([...list, newRow]);
    };

    const handleInputChange = (list, setList, index, field, value) => {
        const newList = [...list];
        newList[index][field] = value;
        setList(newList);
    };

    const handleDeleteRow = (list, setList, index) => {
        const newList = list.filter((_, i) => i !== index);
        setList(newList);
    };

    const handleSaveLists = async () => {
        const courseRef = doc(db, 'Courses', location.state.course);
        await updateDoc(courseRef, { ToDoList: toDoList, ContactList: contactList });
        // Show "Changes Saved!" text
        setShowChangesSaved(true);

        // Hide the text after 4 seconds
        setTimeout(() => {
            setShowChangesSaved(false);
        }, 4000);
    };

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', minHeight: '100vh', flexDirection: 'column' }}>
                <h1 style={{ padding: '0', margin: '30px 0px 10px 0px', fontWeight: '900' }}>Course Operations</h1>
                <div style={{ width: '97%', marginTop: '20px', background: 'white', padding: '20px', marginBottom: '40px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', background: '#9CE3FF' }}>
                        <div
                            style={{cursor: 'pointer', padding: '10px', width: '50%', textAlign: 'center', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: activeTab === 'ToDoList' ? 'bold' : 'normal', background: activeTab === 'ToDoList' ? '#1D5BC0' : '#ededeb', color: activeTab === 'ToDoList' ? 'white' : '#000' }}
                            onClick={() => toggleTab('ToDoList')}>
                            <LuListTodo style={{ marginRight: '4px', fontSize: '17px' }} />| To-Do List
                        </div>
                        <div
                            style={{cursor: 'pointer', padding: '10px', width: '50%', textAlign: 'center', fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: activeTab === 'ContactList' ? 'bold' : 'normal', background: activeTab === 'ContactList' ? '#1D5BC0' : '#ededeb', color: activeTab === 'ContactList' ? 'white' : '#000' }}
                            onClick={() => toggleTab('ContactList')}>
                            <RiContactsBook3Line style={{ marginRight: '4px', fontSize: '17px' }} />| Contact List
                        </div>
                    </div>

                    {activeTab === 'ToDoList' && (
                        <>
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                                <thead>
                                    <tr style={{ background: 'white', color: 'white', textAlign: 'center', borderRadius: '15px' }}>
                                        <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '15px 0px 0px 0px', fontWeight: '300' }}>S#</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Task</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Deadline</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Remarks</th>
                                        <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '0px 15px 0px 0px' }}></th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {toDoList.map((item, index) => (
                                        <tr key={index} style={{ background: index % 2 === 0 ? '#dcdee0' : '#f7f7f7' }}>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center', background: '#2a2f36', color: 'white' }}>{index + 1}</td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>
                                                <input 
                                                    type="text" 
                                                    value={item.task} 
                                                    onChange={(e) => handleInputChange(toDoList, setToDoList, index, 'task', e.target.value)} 
                                                    style={{ width: '100%', height: '100%', padding: '5px 10px', borderRadius: '5px', border: '1px solid #c2c0c0' }} 
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>
                                                <input 
                                                    type="date" 
                                                    value={item.deadline} 
                                                    onChange={(e) => handleInputChange(toDoList, setToDoList, index, 'deadline', e.target.value)} 
                                                    style={{ width: '100%', height: '100%', padding: '5px 10px', borderRadius: '5px', border: '1px solid #c2c0c0' }} 
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}> 
                                                <textarea 
                                                    type="textarea" 
                                                    rows='3'
                                                    value={item.remarks} 
                                                    onChange={(e) => handleInputChange(toDoList, setToDoList, index, 'remarks', e.target.value)} 
                                                    style={{ width: '100%', height: '100%', padding: '5px 10px', borderRadius: '5px', border: '1px solid #c2c0c0' }} 
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>
                                                <button style={{ height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', background: 'white', border: '1px solid red', color: 'red', cursor: 'pointer', padding: '5px' }} onClick={() => handleDeleteRow(toDoList, setToDoList, index)}>
                                                    <MdDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button onClick={() => handleAddRow(toDoList, setToDoList, 'ToDoList')} style={{width: '100%', background: '#fff', border: '1px solid #2a2f36', marginTop: '20px', padding: '10px 20px', color: 'white', borderRadius: '5px', cursor: 'pointer', fontSize: '14px' }}>
                                <IoAddOutline style={{color: '#2a2f36', padding: '0', margin: '0', fontSize: '20px' }}/>   
                            </button>
                        </>
                    )}

                    {activeTab === 'ContactList' && (
                        <>
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                                <thead>
                                    <tr style={{ background: 'white', color: 'white', fontWeight: '900', textAlign: 'center', borderRadius: '15px' }}>
                                        <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '15px 0px 0px 0px', fontWeight: '300' }}>S#</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Contact Title</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Contact Number</th>
                                        <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '0px 15px 0px 0px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactList.map((item, index) => (
                                        <tr key={index} style={{ background: index % 2 === 0 ? '#dcdee0' : '#f7f7f7' }}>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center', background: '#2a2f36', color: 'white' }}>{index + 1}</td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>
                                                <input 
                                                    type="text" 
                                                    value={item.title} 
                                                    onChange={(e) => handleInputChange(contactList, setContactList, index, 'title', e.target.value)} 
                                                    style={{ width: '100%', height: '100%', padding: '5px 10px 5px 10px', borderRadius: '5px', border: '1px solid #c2c0c0' }} 
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>
                                                <input 
                                                    type="text" 
                                                    value={item.number} 
                                                    onChange={(e) => handleInputChange(contactList, setContactList, index, 'number', e.target.value)} 
                                                    style={{ width: '100%', height: '100%', padding: '5px 10px 5px 10px', borderRadius: '5px', border: '1px solid #c2c0c0' }} 
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>
                                                <button style={{ height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', background: 'white', border: '1px solid red', color: 'red', cursor: 'pointer', padding: '5px'}} onClick={() => handleDeleteRow(contactList, setContactList, index)}>
                                                    <MdDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={() => handleAddRow(contactList, setContactList, 'ContactList')} style={{width: '100%', background: '#fff', border: '1px solid #2a2f36', marginTop: '20px', padding: '10px 20px', color: 'white', borderRadius: '5px', cursor: 'pointer', fontSize: '14px' }}>
                                <IoAddOutline style={{color: '#2a2f36', padding: '0', margin: '0', fontSize: '20px' }}/>  
                            </button>
                        </>
                    )}

                    <div style={{position: 'absolute', top: '60px', right: '10px', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', padding: '10px', width: '200px'}}>
                        <button onClick={handleSaveLists} style={{ fontSize: '14px', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                            Update
                        </button>
                        {showChangesSaved && (
                            <p className="fade-in-out" style={{ fontSize: '14px', color: 'green', marginTop: '3px', width: '100%', textAlign: 'center' }}>Changes Saved!</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseOpsList;
