import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import StripeContext from './stripe/StripeContext.js';
import ChooseJourney from './components/Registrations&Login/CheckoutJourney/ChooseJourney.js';
import CheckoutForm from './components/StripeCheckout';

// MAIN PAGES
import Home from './components/MainPages/1_Home';
import About from './components/MainPages/2_About';
import Students from './components/MainPages/3_Students';
import Faculty from './components/MainPages/4_Faculty';
import UpcomingCourses from './components/MainPages/5_UpcomingCourses';
import CoursePage from './components/MainPages/5_UpcomingCourses/CoursePage.js';
import Resources from './components/MainPages/6_Resources';
import Contact from './components/MainPages/7_Contact';
// import VideoCall from './components/MainPages/8_VideoCall';
import Error from './components/MainPages/Error';
import PrivacyPolicy from './components/MainPages/Policies/privacypolicy.js';
import RefundPolicy from './components/MainPages/Policies/refundpolicy.js';
import TermsandConditions from './components/MainPages/Policies/termsandconditions.js';

// STUDENT DOCTORS
import RegistrationStudents from './components/Registrations&Login/Registrations/StudentRegistration.js';
import DashboardStudent from './components/Dashboards/DashboardStudent';
import StudentCertificate from './components/Dashboards/DashboardStudent/CourseCertificate';
import EditProfile from './components/Dashboards/DashboardStudent/EditProfile'

// LOGIN
import Login from './components/Registrations&Login/Login/Login';
import ForgotPassword from './components/Registrations&Login/Login/ForgotPassword';

// DASHBOARD SYS ADMIN
import SysAdmin from './components/Dashboards/DashboardSysAdmin/CreateEvent';
import CourseRegistrations from './components/Dashboards/DashboardSysAdmin/CourseRegistrations';
import CourseRegistrationsList from './components/Dashboards/DashboardSysAdmin/CourseRegistrations/CourseRegistrationsList.js';
import CreateResource from './components/Dashboards/DashboardSysAdmin/CreateResource';
import Registrations from './components/Dashboards/DashboardSysAdmin/Registrations';
import CourseOps from './components/Dashboards/DashboardSysAdmin/CourseOps';
import CourseOpsList from './components/Dashboards/DashboardSysAdmin/CourseOps/CourseOpsList.js';

// BLOG PAGES
import BlogPage from './components/MainPages/6_Resources/BlogPage.js';
import Success from './components/StripeCheckout/Success.js';

// Component to render CheckoutForm with StripeContext
const StripeCheckoutPage = () => (
  <StripeContext>
    <CheckoutForm />
  </StripeContext>
);

function App() {
  return (
    <Router>
      <Routes>
        {/* MAIN PAGES */}
        <Route exact path="/" element={<Home />} /> <Route exact path="/About" element={<About />} /> <Route exact path="/Students" element={<Students />} /> <Route exact path="/Faculty" element={<Faculty />} /> <Route exact path="/UpcomingCourses" element={<UpcomingCourses />} /> <Route exact path="/Course/:slug" element={<CoursePage />} /> <Route exact path="/Resources" element={<Resources />} /> <Route exact path="/Contact" element={<Contact />} /> <Route exact path="/SysAdmin" element={<SysAdmin />} /> <Route exact path="*" element={<Error />} /> <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} /> <Route exact path="/RefundPolicy" element={<RefundPolicy />} /> <Route exact path="/TermsandConditions" element={<TermsandConditions />} /> <Route exact path="/Blog/:id" element={<BlogPage />} />
        {/* <Route exact path="/VideoCall/:roomId" element={<VideoCall />} /> */}

        {/* STUDENT DOCTORS */}
        <Route exact path="/RegistrationStudents" element={<RegistrationStudents />} /> <Route exact path="/DashboardStudent" element={<DashboardStudent />} /> <Route exact path="/StudentCertificate" element={<StudentCertificate />} /> <Route exact path="/EditProfile" element={<EditProfile />} />

        {/* LOGIN */}
        <Route exact path="/Login" element={<Login />} /> <Route exact path="/ForgotPassword" element={<ForgotPassword />} />

        {/* DASHBOARD SYS ADMIN */}
        <Route exact path="/CourseRegistrations" element={<CourseRegistrations />} /> <Route exact path="/CourseRegistrationsList" element={<CourseRegistrationsList />} /> <Route exact path="/Registrations" element={<Registrations />} /> <Route exact path="/CreateResource" element={<CreateResource />} /> <Route exact path="/CourseOps" element={<CourseOps />} /> <Route exact path="/CourseOpsList" element={<CourseOpsList />} />

        {/* STRIPE CHECKOUT */}
        <Route exact path="/ChooseJourney" element={<ChooseJourney />} />
        <Route exact path="/checkout/:id" element={<StripeCheckoutPage />} />
        <Route exact path="/Success" element={<Success />} />

      </Routes>
    </Router>
  );
}

export default App;
