import React from 'react';
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import CoverMain from '../../1_MediaAssets/MainPages/PolicyPages/privacypolicyCover.jpg';

import Navbar from '../../Navbars/NavbarMain/NavbarMain.js'; 
import Footer from '../../Navbars/Footer';
import { useEffect } from 'react';

const PrivacyPolicy = () => { 

    useEffect(() => {
        document.title = "Privacy Policy | REX Medical Events";
    }, []);

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column'}}>
                <div className="parallax-item3" style={{backgroundImage: `url('${CoverMain}')`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', flexDirection: 'column'}}>
                    <h1 style={{color: 'white'}}>Privacy Policy</h1>
                    <p style={{color: 'white', fontWeight: '900'}}>Date effective: 27th May, 2024</p>
                </div> 
            </div>
            <div style={{width: '100%', padding: '30px', background: 'white', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="BlogTextArea2">
                    
                    <p>REX Medical Events ("us", "we", or "our") operates the website <a href='/' target='blank' style={{textDecoration: 'none'}}> www.rexmedicalevents.com </a> (the "Service").</p>
                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
                    <p>We do not collect payment information, sensitive personal information, or offer user sign-up/sign-in functionalities on our website. We solely gather contact information provided voluntarily by users, such as names, email addresses, and phone numbers, for the purpose of communication and marketing initiatives.</p>
                    <p>We utilize Google Analytics to collect non-personal information, including user interests and demographics, to better understand our audience and tailor our marketing efforts accordingly. Google Analytics may collect information such as your device type, browser type, IP address, and browsing behavior, which is governed by Google's Privacy Policy.</p>
                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties. Any information collected is solely used for internal purposes, such as improving our services and providing relevant promotional offers to our users.</p>
                    <p>If you have any questions or concerns regarding our privacy practices, please contact us using the following information:</p>
                    <p>REX Medical Events Phone:<a style={{textDecoration: 'none'}} href="tel:971547773686">+971 54 777 3686</a> Email: <a style={{textDecoration: 'none'}} href="mailto:info@rexmedicalevents.com">info@rexmedicalevents.com</a></p>
                    <p>By using our Service, you consent to the collection and use of information in accordance with this Privacy Policy.</p>
                    
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;