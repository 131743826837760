import Cover from '../../../1_MediaAssets/Homepage/Texture3.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
// eslint-disable-next-line
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

const AllUsers = () => { 
    // eslint-disable-next-line
    const location = useLocation();
    const db = getFirestore();
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterMonth, setFilterMonth] = useState('');
    const [availableMonths, setAvailableMonths] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = [];
                const studentsCollection = collection(db, 'Students');
                const querySnapshot = await getDocs(studentsCollection);
                querySnapshot.forEach((doc) => {
                    data.push({ ...doc.data(), registeredStudentId: doc.id });
                });
                data.sort((a, b) => b.RegistrationTime.seconds - a.RegistrationTime.seconds);
                setRegisteredUsers(data);
                setAvailableMonths(extractAvailableMonths(data));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [db]);

    useEffect(() => {
        document.title = "All Registrations | Admin";
    }, []);

    const extractAvailableMonths = (users) => {
        const months = users.map(user => {
            const date = new Date(user.RegistrationTime.seconds * 1000);
            return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        });
        return [...new Set(months)];
    };

    const handleMonthChange = (e) => {
        setFilterMonth(e.target.value);
    };

    const filteredUsers = filterMonth
        ? registeredUsers.filter(user => {
            const date = new Date(user.RegistrationTime.seconds * 1000);
            const month = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
            return month === filterMonth;
        })
        : registeredUsers;

    function formatTime(unixTimestamp) {
        const date = new Date(unixTimestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutesStr} ${ampm}`;
    }

    function formatDate(unixTimestamp) {
        const date = new Date(unixTimestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
            <img src={Cover} alt='loading' style={{ width: '100px' }} />
        </div>;
    }

    return ( 
        <> 
        <Navbar/>
        <div style={{ padding: '50px 0px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', minHeight: '100vh', flexDirection: 'column' }}>
            
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '97%', position: 'relative' }}>   
                <h1 style={{ padding: '0', margin: '30px 0px 10px 0px', fontWeight: '900' }}>All Registered Users</h1>
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: '0', marginTop: '20px'}}>
                    <label htmlFor="monthFilter" style={{ padding: '0', margin: '0px 10px 0px 0px', fontSize: '14px' }}>Filter by Month:</label>
                    <select id="monthFilter" value={filterMonth} onChange={handleMonthChange} style={{width: '230px', borderRadius: '5px', padding: '5px', fontSize: '14px', border: '2px solid #ddd'}}>
                        <option value=''>All</option>
                        {availableMonths.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div style={{ width: '100%', overflow: 'auto', padding: '-5px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                <table style={{ width: '97%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr style={{ background: 'white', color: 'white', fontWeight: '900', textAlign: 'center', borderRadius: '15px' }}>
                            <th style={{ padding: '8px', fontSize: '14px', width: '40px', background: '#2a2f36', borderRadius: '15px 0px 0px 0px', fontWeight: '300' }}>S#</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Name</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Email Address</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Phone No</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>DoB</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Country</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', background: '#2a2f36', borderTop: 'none', fontWeight: '300' }}>Registration Time</th>
                            <th style={{ padding: '8px', fontSize: '14px', background: '#2a2f36', borderRadius: '0px 15px 0px 0px', fontWeight: '300' }}>Registration Date</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={index} style={{ background: index % 2 === 0 ? '#dcdee0' : '#f7f7f7'}}>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center', background: '#2a2f36', color: 'white', fontWeight: '900' }}>{index + 1}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>{user.Name}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>{user.EmailAddress}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px' }}>{user.PhoneNo}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>{user.DOB}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>{user.Country}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center' }}>{formatTime(user.RegistrationTime.seconds * 1000)}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '14px', textAlign: 'center', borderRight: '1px solid black'  }}>{formatDate(user.RegistrationTime.seconds * 1000)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}

export default AllUsers;
