import Wallpaper from '../../../1_MediaAssets/MainPages/Whoa.png' 
import Texture from '../../../1_MediaAssets/Homepage/Texture.png';
// eslint-disable-next-line
import { getAuth,  setPersistence,signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail  } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain.js';
import { useEffect } from 'react';

const ForgotPassword = () => { 

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const auth = getAuth();
    // eslint-disable-next-line 

   async function handleSubmit(){
    console.log(email);
        try {
            await sendPasswordResetEmail(auth, email);
            navigate('/Login', { state: { message: 'Password reset email sent' } });
            console.log('Password reset email sent');
        } catch (error) {
            console.error('Error sending password reset email:', error);
        }
    }

    function Email(event){
        setEmail(event.target.value);
        console.log(email);
    }

    useEffect(() => {
        document.title = "Forgot Password | REX Medical Events";
    }, []);



    return (
        <>
            <Navbar />
            <div style={{paddingTop: '50px', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Wallpaper}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className='RegistrationWhiteBox' style={{backgroundImage:`url('${Texture}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px'}}>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px', flexDirection: 'column'}}>
                        <h1 style={{fontWeight: '900', color: '#1D5BC0', fontSize: '30px'}}>Welcome Back!</h1>
                        <h5 style={{fontWeight: '900', marginTop: '-8px', color: '#686869'}}>Enter email for the reset link</h5>
                    </div>
                    
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px'}}>

                        <div className='optionBox' style={{marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px'}}> 
                            <div className='OnlyColumnPage2' style={{padding: '0px 30px 0px 30px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px'}}>
                                    <label style={{fontWeight: '900', fontSize: '13px'}}>Email</label>
                                    <input onChange={Email} type="text" id='email' style={{width:"100%", height:'35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px'}}/>
                                </div>
                            </div> 
                        </div>

                        <div className='ButtonofWhiteBox' style={{marginTop: '0'}}>
                            <button onClick={handleSubmit} style={{width: '200px'}}>Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
        )
}

export default ForgotPassword