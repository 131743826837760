import { AiOutlineUserAdd } from "react-icons/ai";
import { MdOutlineLogin } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import Wallpaper from '../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';

const ChooseJourney = () => { 
    
    const location = useLocation();
    // eslint-disable-next-line
    const studentID = location.state.studentID;
    const courseID = location.state.courseID;
    const amount = location.state.amount;
    
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/Login', { state: { courseID: courseID, amount: amount } });
    }

    const handleRegistration = () => {
        navigate('/RegistrationStudents', { state: { courseID: courseID, amount: amount } });
    }

    return (
        <>
        <Navbar />
            <div style={{paddingTop: '50px', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundImage:`url('${Wallpaper}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec'}}>
                
                <div style={{ padding: '0px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', flexWrap: 'wrap', margin: '0px 10px 0px 10px'}}>
                   
                    <div className="MenuItem" onClick={handleLogin}>
                        <p style={{padding: '0', margin: '0', width: '100%', textAlign: 'center'}}>Already have an<br></br>account?</p>
                        <MdOutlineLogin style={{fontSize: '33px'}}/>
                        <p style={{padding: '0', margin: '0', fontWeight: '900'}}>Sign in</p>
                    </div>

                    <div className="MenuItem" onClick={handleRegistration}>
                        <p style={{padding: '0', margin: '0', width: '100%', textAlign: 'center'}}>Create new<br></br>account</p>
                        <AiOutlineUserAdd style={{fontSize: '32px'}}/>
                        <p style={{padding: '0', margin: '0', fontWeight: '900'}}>Register</p>
                    </div>

                </div>
            </div>
        </>
        )
}

export default ChooseJourney