import Cover from '../../../1_MediaAssets/Homepage/Texture3.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const CourseRegistrations = () => {
    const db = getFirestore();
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const fetchData = async () => {
            const coursesCollection = collection(db, 'Courses');
            const q = query(coursesCollection, where("HiddenCourse", "!=", "Yes")); // Query to exclude hidden courses
            
            onAuthStateChanged(auth, async (user) => {
                if (!user) {
                    window.location.href = '/Login';
                } else {
                    const userDocRef = doc(db, 'Students', auth.currentUser.uid);
                    const userDocData = await getDoc(userDocRef);
                    const userData = userDocData.data();

                    if (userData.UserType !== 'admin') {
                        window.location.href = '/DashboardStudent';
                    }
                }
            });

            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCourses(data);
        };

        fetchData();
    }, [auth, db]);

    useEffect(() => {
        document.title = "Course Registrations | Admin";
    }, []);

    const navigateCourse = (courseId) => {
        navigate('/CourseRegistrationsList', { state: { course: courseId } });
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        const options = { day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column' }}>
                <h1 style={{ padding: '0', margin: '30px 0px 10px 0px', fontWeight: '900' }}>Course Registrations</h1>
                <div className="TrinityStripUC" style={{ paddingTop: '20px' }}>
                    {courses.map((course) => (
                        <div key={course.id} className="TrinityStripElementUC" onClick={() => { navigateCourse(course.id) }}>
                            <div className='CourseCover' style={{ backgroundImage: `url('${course.CoverPicture}')` }}>
                                <p style={{ background: '#2a2f36', color: 'white', fontSize: '8px', padding: '2px 10px 2px 10px', borderRadius: '5px 0px 3px 0px' }}>
                                    {course.Type} Course
                                </p>
                            </div>
                            <h1 style={{ fontSize: '16px', marginTop: '10px', color: '#2a2f36', width: '90%' }}>{course.Name}</h1>
                            <p style={{ fontSize: '12px', width: '90%', marginTop: '-7px', fontWeight: '900' }}>
                                {formatDate(course.StartDate)} - {formatDate(course.EndDate)} (GST)
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CourseRegistrations;
