import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Cover from '../../../1_MediaAssets/Homepage/Texture.png';
import { NavLink } from 'react-router-dom';
import Course1Image from '../../../1_MediaAssets/MainPages/CourseCovers/coverPicture1.jpg';
import Course2Image from '../../../1_MediaAssets/MainPages/CourseCovers/coverPicture2.jpg';
import Course3Image from '../../../1_MediaAssets/MainPages/CourseCovers/coverPicture3.jpg';

const HeroCoursesCarousel = () => {

    useEffect(() => {
        const splide = new Splide('.hero-courses-splide', {
            type: 'loop',
            perPage: 1,
            perMove: 1,
            cover: true,
            heightRatio: 9 / 16,
            speed: 300,
        });
        splide.mount();
    }, []);

    const courses = [
        { id: 1, CoverPicture: Course1Image },
        { id: 2, CoverPicture: Course2Image },
        { id: 3, CoverPicture: Course3Image }
    ];

    return (
        <>
            <div style={{ padding: '15px 0px 0px 0px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
                <div className="hero-courses-splide splide" style={{ width: '100vw', marginTop: '30px' }}>
                    <div className="splide__track">
                        <ul className="splide__list">
                            {courses.map(course => (
                                <li className="splide__slide slide-container" key={course.id}>
                                    <div className="slide-content" style={{ backgroundImage: `url('${course.CoverPicture}')` }}>
                                        <div className="overlay2">
                                            <NavLink to='/UpcomingCourses'>
                                                <button style={{ width: '200px', background: '#d5faec', color: 'black' }}>Upcoming Courses</button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroCoursesCarousel;

// Styles for HeroCoursesCarousel
const styles = `
  .slide-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    padding: 0;
  }

  .slide-content {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(29, 91, 192, 0.6);
    color: white;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-container:hover .overlay2 {
    opacity: 1;
  }

  .description {
    margin: 0;
    padding: 10px;
    font-size: 14px;
  }

  /* Media query for responsiveness */
  @media (max-width: 800px) {
    .splide__slide {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

// Inject styles into the document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
