import Cover from '../../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../../Navbars/NavbarSysAdmin/NavbarSys.js';
import { useEffect, useState } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp, updateDoc, doc,getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { storage } from '../../../../firebase/index.js';

const SysAdmin = () => {  

    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [flyerFile, setFlyerFile] = useState(null);
    const [content, setContent] = useState('');
    const [hiddenCourse, setHiddenCourse] = useState(false);
    // eslint-disable-next-line
    const auth = getAuth();
    const db = getFirestore();

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '');
    }

    const handleSubmit = () => {
        const name = document.getElementById('name').value;
        const start = document.getElementById('start').value;
        const end = document.getElementById('end').value;    
        const fee = document.getElementById('fee').value;
        const venue = document.getElementById('venue').value;
        const start_time = document.getElementById('start-time').value;
        const end_time = document.getElementById('end-time').value;
        const type = document.getElementById('type').value;
    
        if (!name || !start || !end || !fee || !venue || !start_time || !end_time || !imageFile || !flyerFile || !content || !type) {
            document.getElementById('error').textContent = 'Please fill out all fields and upload a cover image and flyer.';
            return;
        }

        document.getElementById('ButtonText').textContent = 'Loading...';

        const slug = generateSlug(name);
    
        const userData = {
            Name: name,
            StartDate: start,
            EndDate: end,
            Fee: fee,
            Venue: venue,
            StartTime: start_time,
            EndTime: end_time,
            Content: content,
            Type: type,
            slug: slug,
            Seats: 25,
            RegistrationTime: serverTimestamp(),
            HiddenCourse: hiddenCourse ? "Yes" : "No"
        };

        addDoc(collection(db, `Courses`), userData).then(async (docRef) => {
            const id = docRef.id;

            const coverPictureRef = ref(storage, `Communities/${id}/coverPicture.${imageFile.name.split('.').pop()}`);
            const uploadCoverTask = uploadBytesResumable(coverPictureRef, imageFile);

            uploadCoverTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Cover upload is ' + progress + '% done');
            }, 
            (error) => {
                console.log('Error uploading cover:', error);
            },
            () => {
                getDownloadURL(uploadCoverTask.snapshot.ref).then((coverDownloadURL) => {
                    console.log('Cover available at', coverDownloadURL);
                    updateDoc(doc(db, `Courses/${id}`), { CoverPicture: coverDownloadURL });
                });
            });

            const flyerRef = ref(storage, `Communities/${id}/flyer.${flyerFile.name.split('.').pop()}`);
            const uploadFlyerTask = uploadBytesResumable(flyerRef, flyerFile);

            uploadFlyerTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Flyer upload is ' + progress + '% done');
            }, 
            (error) => {
                console.log('Error uploading flyer:', error);
            },
            () => {
                getDownloadURL(uploadFlyerTask.snapshot.ref).then((flyerDownloadURL) => {
                    console.log('Flyer available at', flyerDownloadURL);
                    updateDoc(doc(db, `Courses/${id}`), { Flyer: flyerDownloadURL });

                    document.getElementById('name').value = ''; 
                    document.getElementById('start').value = ''; 
                    document.getElementById('end').value = ''; 
                    document.getElementById('fee').value = ''; 
                    document.getElementById('venue').value = ''; 
                    document.getElementById('start-time').value = ''; 
                    document.getElementById('end-time').value = ''; 
                    document.getElementById('type').value = 'Live';
                    setImage(null);
                    setImageFile(null);
                    setFlyerFile(null);
                    setContent('');

                    const quill = Quill.find(document.getElementById('editor'));
                    quill.setText('');

                    document.getElementById('error').style.color = 'green';
                    document.getElementById('error').textContent = 'Event posted successfully!';
                    document.getElementById('ButtonText').textContent = 'Post';
                    window.location.reload();
                });
            });
        });
    }

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const period = hour < 12 ? 'AM' : 'PM';
                const hour12 = hour % 12 === 0 ? 12 : hour % 12;
                const minuteStr = minute.toString().padStart(2, '0');
                times.push(`${hour12}:${minuteStr} ${period}`);
            }
        }
        return times;
    }

    const timeOptions = generateTimeOptions();

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImageFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onload = (event) => {
                setImage(event.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleFlyerChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFlyerFile(e.target.files[0]);
        }
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = '/Login';
            } else {
                // eslint-disable-next-line
                const data = [];
                const user = doc(db, 'Students', auth.currentUser.uid);

                getDoc(user).then((doc) => {
                    const data = doc.data();

                    if(data.UserType === 'admin'){
                        return;
                    }
                    else{
                        window.location.href = '/DashboardStudent';
                    }
                });
            }
        });
        const initializeQuill = (selector, stateKey) => {
            const quill = new Quill(selector, {
                theme: 'snow'
            });
            quill.on('text-change', () => {
                const content = quill.root.innerHTML;
                setContent(content);
            });
        };
        initializeQuill('#editor', 'data');
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        document.title = "Create Event | Admin"; // Set the document title if course is already available
    }, []);

    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#dcdee0', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
                <div className='RegistrationWhiteBox' style={{ backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginTop: '30px', marginBottom: '30px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '30px 30px 0px 30px', flexDirection: 'column' }}>
                        <h1 style={{ fontWeight: '900', fontSize: '30px', color: 'black' }}>Create an event</h1>
                    </div>
    
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '-15px' }}>
                        <div className='optionBox' style={{ marginTop: '0', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px' }}>
                            <div className='OnlyColumnPage2' style={{ padding: '0px 30px 0px 30px' }}>
    
                                <div style={{ width: '100%', height: '200px', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightgrey', color: 'black', fontWeight: '900', borderRadius: '10px', position: 'relative', overflow: 'hidden' }} >
                                    {image ? (
                                        <img src={image} alt='Course Cover' style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} />
                                    ) : (
                                        <span>Upload cover</span>
                                    )}
                                </div>
    
                                <div style={{ display: 'flex', width: '100%', marginTop: '10px', gap: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px', marginTop: '10px' }}>Course cover</label>
                                        <input type='file' accept='image/*' style={{ fontSize: '12px' }} onChange={handleImageChange} />
                                    </div>
    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px', marginTop: '10px' }}>Course flyer</label>
                                        <input type='file' accept='application/pdf' style={{ fontSize: '12px' }} onChange={handleFlyerChange} />
                                    </div>
                                </div>
    
                                <div style={{ display: 'flex', width: '100%', marginTop: '10px', gap: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Course title</label>
                                        <input type="text" id='name' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                    </div>
    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Event type</label>
                                        <select id='type' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '12px' }}>
                                            <option selected value="Live">Live</option>
                                            <option value="Online">Online</option>
                                        </select>
                                    </div>
                                </div>
    
                                <div style={{ display: 'flex', width: '100%', marginTop: '10px', gap: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Start date</label>
                                        <input type="date" id='start' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                    </div>
    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>End date</label>
                                        <input type="date" id='end' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                    </div>
                                </div>
    
                                <div style={{ display: 'flex', width: '100%', marginTop: '10px', gap: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Enrolment fee ($)</label>
                                        <input type="text" id='fee' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                    </div>
    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Venue</label>
                                        <input type="text" id='venue' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '14px' }} />
                                    </div>
                                </div>
    
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>Start time</label>
                                        <select id='start-time' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '12px' }}>
                                            {timeOptions.map(time => (
                                                <option key={time} value={time}>{time}</option>
                                            ))}
                                        </select>
                                    </div>
    
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginLeft: '10px' }}>
                                        <label style={{ fontWeight: '900', fontSize: '13px' }}>End time</label>
                                        <select id='end-time' style={{ width: "100%", height: '35px', borderRadius: '5px', padding: '8px', border: '1px solid grey', fontSize: '12px' }}>
                                            {timeOptions.map(time => (
                                                <option key={time} value={time}>{time}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                
    
                                <div style={{ display: 'flex', gap: '10px', width: '100%', margin: '15px 0px 15px 0px'}}>
                                    <label style={{ fontWeight: '900', fontSize: '13px', padding: '0', margin: '0' }}>Hidden Course?</label>
                                    <input type="checkbox" id='hiddenCourse' checked={hiddenCourse} onChange={() => setHiddenCourse(!hiddenCourse)} />
                                </div>
    
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
                                    <div id="editor" className="my-quill-editor" style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
                                    </div>
                                </div>
    
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: '10px', height: '30px' }}>
                                    <label style={{ fontWeight: '900', fontSize: '13px', color: 'red', padding: '0', margin: '0' }} id='error'></label>
                                </div>
    
                        </div> 
                    </div>

                    <div className='ButtonofWhiteBox' style={{ marginTop: '-15px'}}>
                        <button style={{width: '200px', background: 'black'}} id='ButtonText' onClick={handleSubmit}>Post</button>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}

export default SysAdmin
