import '@splidejs/splide/dist/css/splide.min.css';

// import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import FacultyW from './FacultyWidget.js';
import Footer from '../../Navbars/Footer/index.js';

const Faculty = () => { 
  return (
    <>
      <Navbar />
      <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', width: '100vw', paddingTop: '40px'}}>
        <FacultyW />
      </div>
      <Footer />
    </>
  );
}

export default Faculty;
