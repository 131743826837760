import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Logo from '../../1_MediaAssets/Homepage/Logo.png'
import { NavLink } from 'react-router-dom';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';

const Students = () => { 
    return (
        <>
        <Navbar/>
            <div style={{ backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', flexDirection: 'column'}}>
                <img src={Logo} alt='logo' style={{height: '100px'}} />
                <h3 style={{padding: '0', margin: '20px', fontWeight: '900'}}>404 Page not found</h3>
                <NavLink to="/">
                    <button style={{width: '170px', borderRadius: '10px', background: '#8FF2CC', color: 'black'}}>Home</button>
                </NavLink>
            </div>
        </>
        )
}

export default Students 