import React from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Cover3 from '../../../1_MediaAssets/Homepage/Texture2.png';
import Logo1 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo1.png';
import Logo2 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo2.png';
import Logo3 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo3.png';
import Logo4 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo4.png';
import Logo5 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo5.png';
import Logo6 from '../../../1_MediaAssets/MainPages/PartnerLogos/Logo6.png';


const PartnersWidget = () => { 

  const partnerContent = [
    {
        icon: <img src={Logo1} alt="Cosmetic Gynecology" className="partner-image" />
    },
    {
        icon: <img src={Logo2} alt="Aesthetic Medicine" className="partner-image" />
    },
    {
        icon: <img src={Logo3} alt="Laparoscopic Surgery" className="partner-image" />
    },
    {
        icon: <img src={Logo4} alt="Hysteroscopy" className="partner-image" />
    },
    {
        icon: <img src={Logo5} alt="Urogynecological" className="partner-image" />
    },
    {
        icon: <img src={Logo6} alt="Urogynecological" className="partner-image" />
    },
  ];

  return (
    <div style={{backgroundImage:`url('${Cover3}')`, padding: '50px 30px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', flexDirection: 'column'}}>
      <h1 style={{ padding: '0', fontWeight: '900', textAlign: 'center', width: '95%', marginBottom: '10px', color: 'white' }}>Our <span className='fontSpecial' style={{color: '#8FF2CC'}}>Affiliations</span></h1>
      <Splide style={{width: '100vw'}}
        options={{
          perPage: 5,
          perMove: 1,
          breakpoints: {
            1200: { perPage: 2 },
            800: { perPage: 1 },
          },
          gap: '1rem',
          pagination: false,
          arrows: true,
          autoplay: true,
        }}
      >
        {partnerContent.map((course, index) => (
          <SplideSlide key={index} style={{padding: '10px 0px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="partner-card">
              {course.icon}
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default PartnersWidget;
