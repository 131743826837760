import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_live_51OmYduHreLomtWGUcGWwPgnFzBBrpa0OvvasIcKryQZfKYOXVmZq2vtCEwuh6w9O2XSywCuqJdDWbLMNgDNqPMfJ00kUIee0yW");
// const stripePromise = loadStripe("pk_test_51OmYduHreLomtWGURqoSfvbwvzXx1pYTz6bTDwCj8SjvoyfOS7xgWc8NtRp9lrunzn5P5CSNI0wflu7Poctu9OOD007d0xGOKk");

const StripeContext = ({ children }) => {
    return (
        <Elements stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default StripeContext;
