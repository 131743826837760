import { FaLongArrowAltRight } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { FaUserAlt } from "react-icons/fa";
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import { getFirestore, collection, getDocs, limit, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Resources = () => { 
    const db = getFirestore();
    const [rec, setRec] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = [];
                const coursesCollection = collection(db, 'Resources');
                const Query = query(coursesCollection, limit(2));
                const querySnapshot = await getDocs(Query);
                querySnapshot.forEach((doc) => {
                    data.push({...doc.data(), id: doc.id});
                });
                setRec(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [db]);

    function NavigateBlog(item){
        navigate(`/Blog/${item.id}`, {state: {item: item}});
    }

    return (
        <>
            <div style={{ padding: '50px 0px 20px 0px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
                <section style={{width: '100vw', scrollBehavior: 'smooth'}}>
                    <h1 style={{ padding: '0', margin: '10px 0px 10px 0px', fontWeight: '900', width: '100%', textAlign: 'center' }}>Latest News &  <span className='fontSpecial' style={{color: '#1D5BC0'}}>Blogs</span></h1>

                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', width: '100vw', height: '100%', background: '#d5faec', padding: '40px 20px 40px 20px', gap: '20px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                        {rec.map((item, index) => (
                            <div onClick={() => NavigateBlog(item)} key={index} className="BlogSection">
                                <div style={{borderRadius: '10px 0px 0px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', height: '100%', backgroundImage:`url('${item.Image}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div>
                                <div className="BlogSectionRightDiv" style={{cursor: 'pointer'}}>
                                    <p style={{padding: '0', margin: '0', fontSize: '12px', fontWeight: '900', color: '#1D5BC0'}}>
                                        <SlCalender style={{margin:'0px 0px 2px 0px', color: '#1D5BC0'}}/> {item.createdAtDate && new Date(item.createdAtDate.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}  &nbsp;|&nbsp;
                                        <FaUserAlt style={{margin:'0px 0px 2px 3px', color: '#1D5BC0'}} /> {item.Author}
                                    </p>
                                    <h1 style={{fontSize: '25px', color: '#1D5BC0', margin: '10px 0px 5px 0px'}}>{item.Name}</h1>
                                    <div>
                                        {item.Data.replace(/<[^>]+>/g, '').length > 400 ? item.Data.replace(/<[^>]+>/g, '').substring(0, 400) + "..." : item.Data.replace(/<[^>]+>/g, '')}
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                        <p style={{fontSize: '13px', marginTop: '10px', color: '#1D5BC0'}}>
                                            <FaLongArrowAltRight style={{fontSize: '20px', color: '#1D5BC0', marginBottom: '2px'}} /> keep reading
                                        </p>
                                    </div> 
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='buttonerdivv'> 
                        <NavLink to='/Resources'>
                            <button style={{ width: '170px', marginBottom: '30px', background: '#1D5BC0', color: 'white' }}>All Blogs</button>
                        </NavLink>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Resources;
