import { IoDocumentText } from "react-icons/io5";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Cover2 from '../../1_MediaAssets/Homepage/Texture3Light.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer';
import Contact from '../7_Contact/ContactWidget.js';
import LogoBlue from '../../1_MediaAssets/Homepage/LogoBlue.png';

const CoursePage = () => {
  const { slug } = useParams();
  const location = useLocation();
  const history = useNavigate();
  const [course, setCourse] = useState(location.state?.course);
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  const fetchCourseBySlug = async (slug) => {
    const coursesCollection = collection(db, 'Courses');
    const q = query(coursesCollection, where('slug', '==', slug));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      history('/UpcomingCourses');
    } else {
      querySnapshot.forEach((doc) => {
        const courseData = { ...doc.data(), id: doc.id };
        setCourse(courseData);
        document.title = courseData.Name; // Set the document title here
      });
    }
  };

  useEffect(() => {
    if (!course) {
      fetchCourseBySlug(slug);
    } else {
      document.title = course.Name; // Set the document title if course is already available
    }
    // eslint-disable-next-line
  }, [course, slug]);

  const handleClick = (amount) => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate(`/checkout/${slug}`, { state: { courseID: course.id} });
      } else {
        // eslint-disable-next-line
        const docRef = doc(db, "Students", auth.currentUser.uid);
        // eslint-disable-next-line
        const registeredCollection = collection(db, 'Courses', course.id, 'RegisteredStudents');
        // eslint-disable-next-line
        const courseRef = doc(db, 'Courses', course.id);
        // eslint-disable-next-line
        const userRegCollection = collection(db, 'Students', auth.currentUser.uid, 'RegisteredCourses');
        // eslint-disable-next-line
        navigate(`/checkout/${slug}`, { state: { studentID: auth.currentUser.uid, courseID: course.id} });
      }
    });
  };

  const handleDownloadFlyer = async () => {
    try {
      const docRef = doc(db, 'Courses', course.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const flyerURL = docSnap.data().Flyer;
        if (flyerURL) {
          const link = document.createElement('a');
          link.href = flyerURL;
          link.target = '_blank';
          link.setAttribute('download', 'flyer.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Flyer URL not found.');
        }
      } else {
        console.error('Course document does not exist.');
      }
    } catch (error) {
      console.error('Error downloading flyer:', error);
    }
  };

  const formatDate = (dateString) => {
    // Split the dateString to extract year, month, and day
    const [year, month, day] = dateString.split('-');
    
    // Create a date object
    const date = new Date(year, month - 1, day);
  
    // Format the date
    const options = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };
  

  if (!course) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
      <img src={LogoBlue} alt='nothingness' style={{ width: '100px' }} />
    </div>;
  }

  return (
    <>
      <Navbar />
      <div style={{ paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div style={{ display: 'flex', borderTop: '1px solid white', justifyContent: 'center', alignItems: 'center', background: '#1D5BC0', width: '100%', flexDirection: 'column', backgroundImage: `url('${Cover2}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <h1 style={{ padding: '0', margin: '40px 0px 0px 0px', fontWeight: '900', color: '#8FF2CC', width: '95%', textAlign: 'center' }}>{course.Name}</h1>
          <p style={{ borderTop: '1px solid #8FF2CC', width: '60%', margin: '10px' }}></p>
          <p style={{ textAlign: 'center', marginBottom: '40px', fontWeight: '900', color: '#8FF2CC' }}>{formatDate(course.StartDate)} - {formatDate(course.EndDate)} (GST)</p>
        </div>

        <div className='CoursePageMainDiv'>
          <div className='CoursePageLeftDiv'>
            <div className='CourseCover' style={{ backgroundImage: `url('${course.CoverPicture}')`, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <p style={{ position: 'absolute', bottom: '0', right: '0', background: '#000', color: 'white', fontSize: '12px', padding: '2px 10px 2px 10px', borderRadius: '5px 0px 3px 0px' }}>
                  {course.Type} Course
              </p>
              <p style={{ position: 'absolute', top: '0', left: '0', textAlign: 'center', background: 'red', color: 'white', fontSize: '12px', padding: '5px', borderRadius: '0px 20px 20px 0px', height: '28px', margin: '0', maxWidth: '150px' }}>
                  Only {course.Seats} seats left!
              </p>
            </div>
            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', padding: '10px 15px', gap: '10px', flexDirection: 'column' }}>
              <h1>{course.Name}</h1>
              <div dangerouslySetInnerHTML={{ __html: course.Content }} />
              <button style={{ width: '100%', marginTop: '20px', height: '50px', background: '#1D5BC0' }} onClick={handleDownloadFlyer}>
                <IoDocumentText style={{ marginBottom: '3px' }}/> Download flyer</button>
            </div>
          </div>

          <div className='CoursePageRightDiv'>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '7px', color: 'black', marginTop: '10px', width: '95%' }} >
              <p>Date:&nbsp;</p><p style={{ textAlign: 'right' }}>{formatDate(course.StartDate)} - {formatDate(course.EndDate)} (GST)</p>
              <p>Course type:&nbsp;</p><p style={{ textAlign: 'right' }}>{course.Type}</p>
              <p>Starting time:&nbsp;</p><p style={{ textAlign: 'right' }}>{course.StartTime} (GST)</p>
              <p>Ending time:&nbsp;</p><p style={{ textAlign: 'right' }}>{course.EndTime} (GST)</p>
              <p>Venue:&nbsp;</p><p style={{ textAlign: 'right' }}>{course.Venue}</p>
              <p>Registration fee:&nbsp;</p><p style={{ textAlign: 'right' }}>${course.Fee}</p>
            </div>
            <button onClick={() => { handleClick(course.Fee) }} style={{ fontWeight: '900', background: '#1D5BC0', color: 'white', width: '100%', marginTop: '20px', marginBottom: '20px' }}>Book Now</button>
            <Contact />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CoursePage;
