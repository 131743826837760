import '../NavbarMain/Navbar.css';
import'../../1_MediaAssets/Styles/App.css';
import Burger from '../../1_MediaAssets/Homepage/Burger.png';
import LogoWhite from '../../1_MediaAssets/Homepage/FullLogoWhite.png';
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, signOut } from "firebase/auth";

const NavbarSysAdmin = () => {
  const [isChecked, setIsChecked] = useState(false); 
  const navRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function closeNavvy() {
    setIsChecked(!isChecked);
  };

  function signout(){
    const auth = getAuth();
    signOut(auth).then(() => {
        localStorage.removeItem('userId');
        console.log('Signed Out');
        window.location.href = '/';
    }).catch((error) => {
        console.log(error);
    });

}
  return (
    <>
    <nav ref={navRef} style={{zIndex: '100', background: '#2a2f36'}}>
      <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange} />
      
      <NavLink className="nav-link" style={{margin: '5px 8px 0px 15px', zIndex: '2'}} to="/">
        <img src={LogoWhite} alt='LogoWhite' style={{ width: '215px', height: '30px', marginLeft: '5px', marginRight: '-20px' }}></img>
        <p style={{color: 'white', fontWeight: '300', fontSize: '10px', width: '215px', textAlign: 'right', marginTop: '-8px'}}>admin</p>
      </NavLink>
 
      <label htmlFor="check" className="checkbtn">
        <img src={Burger} alt='Burger' className='Burger' style={{width: '27px', height: '22px', filter: 'brightness(1000%)'}}></img>
      </label>
      
      <ul style={{zIndex: '1'}}>
        <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={closeNavvy} to="/SysAdmin" >
                Create event
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={closeNavvy} to="/CreateResource" >  
                Create resource
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={closeNavvy} to="/CourseRegistrations" >
              Course registrations
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={closeNavvy} to="/CourseOps" >
              Course operations
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={closeNavvy} to="/Registrations" >
                All registrations
            </NavLink>
        </li>
        {/* <li>
            <button style={{width: '200px', height: '40px', borderRadius: '5px', background: 'red', color: 'white', border: 'none', marginTop: '10px'}} onClick={signout}>Sign out</button>
        </li> */}

        <li className='DPhiderReverse'>
          <NavLink className="nav-link" style={({ isActive }) => ({ color: isActive ? '#dcdee0' : 'white' })} onClick={signout} to="/Login">
            Sign out 
          </NavLink>
        </li>
        
          <button className="DPhider" style={{position: 'absolute', right: '0', top: '5px', background: 'transparent', border: '2px solid white', borderRadius: '50px', paddingLeft: '20px', paddingRight: '20px', marginRight: '10px', fontSize: '12px'}} onClick={signout}>Sign out</button>
      </ul>
      
    </nav> 
    </>
  );
};

export default NavbarSysAdmin;
