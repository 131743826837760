import React, { useEffect, useState, useRef } from 'react';
import Cover from '../../../1_MediaAssets/MainPages/Whoa.png';
import Navbar from '../../../Navbars/NavbarMain/NavbarMain.js';
// import Footer from '../../../Navbars/Footer';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const EditProfile = () => { 
    const [userData, setUserData] = useState({
        Name: '',
        EmailAddress: '',
        PhoneNo: '',
        DOB: '',
        Country: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const timeoutRef = useRef(null);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userDoc = doc(db, 'Students', user.uid);
                getDoc(userDoc).then(docSnap => {
                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                    }
                });
            } else {
                window.location.href = '/Login';
            }
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const auth = getAuth();
        const db = getFirestore();
        const userDoc = doc(db, 'Students', auth.currentUser.uid);
        updateDoc(userDoc, userData).then(() => {
            setErrorMessage('Profile Updated Successfully!');
            timeoutRef.current = setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }).catch((error) => {
            console.error('Error updating profile: ', error);
            setErrorMessage('Failed to update profile.');
        });
    };

    useEffect(() => {
        document.title = "Edit Profile | REX Medical Events";
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    
    return (
        <> 
            <Navbar/>
            <div style={{ paddingTop: '50px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d1f2ff', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
                <form onSubmit={handleSubmit} style={{ background: 'white', padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', width: '90%', maxWidth: '500px' }}>
                    <h2 className='fontSpecial' style={{ marginBottom: '20px' }}>Edit Profile</h2> 
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="Name" style={{ display: 'block', marginBottom: '2px' }}>Full name</label>
                        <input type="text" id="Name" name="Name" value={userData.Name} onChange={handleChange} style={{ fontSize: '14px', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} required />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="EmailAddress" style={{ display: 'block', marginBottom: '2px' }}>Email address</label>
                        <input type="EmailAddress" id="EmailAddress" name="EmailAddress" value={userData.EmailAddress} disabled style={{ fontSize: '14px', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }} />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="PhoneNo" style={{ display: 'block', marginBottom: '2px' }}>Phone number</label>
                        <input type="tel" id="PhoneNo" name="PhoneNo" value={userData.PhoneNo} onChange={handleChange} style={{ fontSize: '14px',width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} required />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="DOB" style={{ display: 'block', marginBottom: '2px' }}>Date of Birth</label>
                        <input type="date" id="DOB" name="DOB" value={userData.DOB} onChange={handleChange} style={{ fontSize: '14px', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} required />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="Country" style={{ display: 'block', marginBottom: '2px' }}>Country</label>
                        <input type="text" id="Country" name="Country" value={userData.Country} onChange={handleChange} style={{fontSize: '14px', width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} required />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '15px' }}>
                        <p id='error' style={{ fontSize: '10px', color: 'green' }}>{errorMessage}</p>
                    </div>
                    <button type="submit" style={{ width: '100%', padding: '12px 10px 12px 10px', borderRadius: '5px', fontSize: '14px', marginTop: '10px', background: '#000'}}>Save Changes</button>
                </form>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default EditProfile;
