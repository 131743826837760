import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import { useNavigate, NavLink } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, where  } from "firebase/firestore";
import { useState,useEffect } from "react";

const UpcomingCourses = () => { 

    

    const db = getFirestore();
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        var data = [];
        const coursesCollection = collection(db, 'Courses');
        const q = query(coursesCollection, where("HiddenCourse", "!=", "Yes")); // Query to exclude hidden courses
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                data.push({...doc.data(), id: doc.id});
            });
    
            // Sort the data by StartDate
            data.sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate));
    
            setCourses(data);
        }); 
        // eslint-disable-next-line
    }, []);

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        const options = { day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    const createSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '');
    };

    function navigateCourse(course){
        const slug = createSlug(course.Name);
        navigate(`/Course/${slug}`, { state: { course: course } });
    }

    return (
        <> 
            <div style={{ padding: '20px 10px 20px 10px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
            <h1 style={{padding: '0', margin: '30px 0px 10px 0px', fontWeight: '900'}}><span className='fontSpecial' style={{color: '#1D5BC0'}}>Upcoming</span> Courses</h1>
                <p style={{ textAlign: 'center', width: '92vw', fontSize: '14px', maxWidth: '1200px', padding: '5px 0px 0px 0px' }}>At Rex Medical Events, we are proud to offer top-tier medical training courses designed for healthcare professionals worldwide. Guided by an esteemed international faculty with hands-on experience, our programs cater to General Practitioners, Urogynecologists, Surgeons, and Dermatologists. Participate in our courses to improve your expertise and broaden your knowledge in a supportive and engaging learning environment. </p>
                <div className="TrinityStripUC">
                    {courses.map((course, i) => (
                    <div key={i} onClick={() => { navigateCourse(course) }} className="TrinityStripElementUC">
                        <div className='CourseCover' style={{ backgroundImage: `url('${course.CoverPicture}')`}}>
                            <p style={{ position: 'absolute', bottom: '0', right: '0', background: '#000', color: 'white', fontSize: '9px', padding: '2px 10px 2px 10px', borderRadius: '5px 0px 3px 0px' }}>
                                {course.Type} Course
                            </p>
                            <p style={{ position: 'absolute', bottom: '0', left: '0', textAlign: 'center', background: 'red', color: 'white', fontSize: '9px', padding: '5px', borderRadius: '0px 20px 20px 0px', height: '23px', margin: '0', maxWidth: '100px' }}>
                                Only {course.Seats} seats left!
                            </p>
                        </div>
                        <h1 style={{ fontSize: '16px', marginTop: '10px', color: '#1D5BC0', width: '90%' }}>{course.Name}</h1>
                        <p style={{ fontSize: '12px', width: '90%', marginTop: '-7px', fontWeight: '900' }}>
                        {formatDate(course.StartDate)} - {formatDate(course.EndDate)} (GST)
                        </p>
                    </div>
                    ))}
                </div>
                <div className='buttonerdivv' style={{marginTop: '10px'}}>  
                    <NavLink to='/UpcomingCourses'>
                        <button style={{ width: '170px', marginBottom: '30px', background: '#1D5BC0', color: 'white' }}>All Courses</button>
                    </NavLink>
                </div>
            </div>
            
        </>
        )
}

export default UpcomingCourses 