import { NavLink } from "react-router-dom";
import Cover from '../../1_MediaAssets/Homepage/Texture.png'
import Cover2 from '../../1_MediaAssets/Homepage/Texture3Light.png'
import Cover3 from '../../1_MediaAssets/Homepage/Texture3.png'
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import About1 from '../../1_MediaAssets/MainPages/About1.jpg';
import About2 from '../../1_MediaAssets/MainPages/About2.jpg';
import CEO from '../../1_MediaAssets/MainPages/CEO.jpeg';
import Founder from '../../1_MediaAssets/MainPages/Founder.jpg';
import Footer from '../../Navbars/Footer/index.js';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import MeetTheTeam from './MeetTheTeam.js';
import { useEffect } from "react";

const About = () => { 

    useEffect(() => {
        document.title = "About | REX Medical Events";
    }, []);


    return (
    <>
        <Navbar/>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100vw', height: '100%'}}> 
            <div className='AboutImgTextDiv' style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>         
                <div className='AboutImgHolder'>
                    <img src={About1} alt='PawHeart' className='MaxWidthImage'/>
                    <img src={About2} alt='PawHeart' className='DPhider' style={{height: '20vw', marginLeft: '-120px', width: '20vw', borderRadius: '500px'}}/>
                </div>
                <div className='AboutTextHolder'>
                    <TextAnim Body={
                        <h2 className='fontSpecial'>Advancing <span className='fontSpecial' style={{color: '#1D5BC0'}}> Medical Excellence </span> with REX Medical Events</h2>
                    }/>
                    <TextAnim Body={
                        <p style={{color: 'black', marginTop: '10px'}}>At Rex Medical Events, we are committed to revolutionizing the field of healthcare education. We don't just plan events; we create comprehensive learning experiences that empower medical professionals with the skills, knowledge, and associations they need to excel in their fields.</p>
                    }/>
                    <TextAnim Body={
                        <p style={{color: 'black', marginTop: '10px'}}>Our ongoing dedication covers a wide range of medical fields. We offer advanced courses in Cosmetic Gynaecology, IVF and Reproductive Medicine, Endoscopy, and Laparoscopy, consistently leading the way in providing valuable educational opportunities for healthcare professionals worldwide. </p>
                    }/>
                    <TextAnim Body={
                        <p style={{color: 'black', marginTop: '10px'}}>We place a high value on continuous learning and professional skill development. Our courses integrate theoretical knowledge with practical experience to equip participants with the necessary skills to enhance their professional practice.</p>
                    }/>
                    <TextAnim Body={
                        <NavLink to='/UpcomingCourses'>
                            <button style={{ width: '200px', marginTop: '20px', background: '#1D5BC0', color: 'white' }}>Upcoming Courses</button>
                        </NavLink>
                    } />
                </div>
            </div>

            <div style={{ width: '100vw', backgroundColor: '#8FF2CC',  display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '40px 20px', flexDirection: 'column', backgroundImage:`url('${Cover3}')`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                
                <TextAnim Body={
                    <h2 className="fontSpecial" style={{color: '#1D5BC0', marginTop: '10px', fontWeight: '900'}}>Why Choose Rex Medical Events?</h2>
                }/>
                <TextAnim Body={
                    <p style={{color: 'black', marginTop: '10px'}}>Expert-Led Courses: Learn from renowned professionals in a variety of medical disciplines who offer practical expertise and valuable perspectives.</p>
                }/>
                <TextAnim Body={
                    <p style={{color: 'black', marginTop: '10px'}}>Comprehensive Curriculum: Our courses encompass a diverse array of expertise, guaranteeing that you discover the ideal match for your professional growth.</p>
                }/>
                <TextAnim Body={
                    <p style={{color: 'black', marginTop: '10px'}}>Global Community: Establish connections with coworkers and mentors from various parts of the world, creating a community focused on education and assistance.</p>
                }/>
                <TextAnim Body={
                    <p style={{color: 'black', marginTop: '10px'}}>Innovative Learning: Stay ahead with courses that include the latest advancements and technologies in healthcare.</p>
                }/>
                <TextAnim Body={
                    <p style={{color: 'black', marginTop: '10px'}}>Join the global community of healthcare professionals who trust Rex Medical Events for their educational requirements. Together, we are actively influencing the trajectory of healthcare by engaging in progressive educational activities.</p>
                }/>
            </div>

 
            <div style={{ width: '100vw', backgroundColor: '#fff',  display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px'}}>
                <div style={{ width: '100%', display: 'flex', padding: '20px', textAlign: 'center', flexDirection: 'column'}}>
                    <TextAnim Body={
                        <h1 style={{ width: '100%', color: 'black', fontWeight: '900', marginBottom: '30px'}}>Exploring Our <span className='fontSpecial' style={{color: '#1D5BC0'}}>Leadership</span></h1>
                    }/>
                    <div className='MeetTheTeam'>
                        <div className='meettheTeamDivier' style={{backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center', backgroundColor: '#1D5BC0'}}>
                            <img src={CEO} alt='PawHeart' className='HoverImage' style={{height: '300px', width: '220px', borderRadius: '7px'}}/>
                            <div className='TiffDiv'>
                                <TextAnim Body={
                                    <h4 style={{fontWeight: '900', color: '#8FF2CC'}}>Tiffany Delacruz </h4>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '12px', marginTop: '-8px', fontWeight: '900', color: 'white'}}>Chief Executive Officer</p>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '13px', marginTop: '15px', color: 'white'}}>Tiffany Delacruz, CEO of Rex Medical Events, transitions from her veterinary roots to lead a global force in medical education. With a background in preventive medicine, she leverages her expertise to empower healthcare professionals worldwide. Under her guidance, Rex Medical Events offers cutting-edge programs, shaping the future of healthcare through innovation and excellence. Tiffany's commitment to advancing medical knowledge and patient care resonates in every initiative, driving transformative change in the industry.</p>
                                }/>
                            </div>
                        </div>
 
                        <div className='meettheTeamDivier' style={{backgroundImage:`url('${Cover2}')`, backgroundPosition: 'center', backgroundColor: '#1D5BC0'}}>
                            <img src={Founder} alt='PawHeart' className='HoverImage' style={{height: '300px', width: '220px', borderRadius: '7px'}}/>
                            <div className='TiffDiv'>
                                <TextAnim Body={
                                    <h4 style={{fontWeight: '900', color: '#8FF2CC'}}>Johnny Dominguez</h4>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '12px', marginTop: '-8px', fontWeight: '900', color: 'white'}}>Founder</p>
                                }/>
                                <TextAnim Body={
                                    <p style={{fontSize: '13px', marginTop: '15px', color: 'white'}}> Johnny Dominguez, the visionary force behind Rex Medical Events, brings his expertise in computer science to pioneer a modernized approach to medical education. His innovative blend of technology and medical knowledge reshapes traditional notions of medical education, crafting a stress-free experience for doctors globally. Under his leadership, Rex Medical Events becomes a globally renowned training organization, empowering medical professionals with cutting-edge skills and knowledge essential for their growth.</p>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MeetTheTeam/>

        </div>
        <Footer />
    </>
    )
}

export default About