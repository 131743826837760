import React from 'react';
import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import CoverMain from '../../1_MediaAssets/MainPages/PolicyPages/refundpolicyCover.jpg';

import Navbar from '../../Navbars/NavbarMain/NavbarMain.js'; 
import Footer from '../../Navbars/Footer';
import { useEffect } from 'react';

const RefundPolicy = () => { 

    useEffect(() => {
        document.title = "Refund Policy | REX Medical Events";
    }, []);


    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '50px', backgroundImage: `url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column'}}>
                <div className="parallax-item3" style={{backgroundImage: `url('${CoverMain}')`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <h1 style={{color: 'white'}}>Refund Policy</h1>
                </div> 
            </div> 
            <div style={{width: '100%', padding: '30px', background: 'white', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="BlogTextArea2">
                    <p>At Rex Medical Events, we understand that circumstances may arise requiring you to cancel your enrollment in a course. We aim to be transparent and fair in our refund policy. Please review the following guidelines regarding cancellations and refunds:</p>
                    <h2 style={{marginTop: '20px'}}>Cancellation Due to Unforeseen Circumstances:</h2>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	If a course is canceled entirely due to unforeseen circumstances, emergencies, or reasons related to the venue or faculty, you will receive a 100% refund of the course fee.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	In the event of a mid-course cancellation, 50% of the course fee will be refunded.</p>

                    <h2 style={{marginTop: '20px'}}>Refund for Cancellation by Participants:</h2>
                    <p>Cancellation requests received:</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	42 days (six weeks) before the course commencement: Full refund minus 10% administration and registration charges, plus bank/postage charges.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	28 days (four weeks) before the course commencement: 50% refund plus bank/postage charges.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	14 days (two weeks) before the course commencement: 25% refund plus bank/postage charges.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Within 14 days of the course commencement: The full fee will be forfeited.</p>

                    <h2 style={{marginTop: '20px'}}>Non-Refundable Costs:</h2>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Please note that we are unable to refund any travel, accommodation, or sustenance costs incurred in relation to attending the course.</p>
                    
                    <h2 style={{marginTop: '20px'}}>Request Submission:</h2>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	All cancellation requests must be submitted in writing Rex Medical Events via email to company email.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Requests will be processed based on the date of receipt and the applicable refund policy.</p>
                    
                    <h2 style={{marginTop: '20px'}}>Bank/Postage Charges:</h2>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Bank and postage charges associated with refunds will be deducted from the refund amount.</p>

                    <h2 style={{marginTop: '20px'}}>Policy Updates:</h2>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	Rex Medical Events reserves the right to update or modify this cancellation and refund policy as needed. Any changes will be communicated promptly.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;•	By enrolling in our courses, you acknowledge and agree to abide by the terms and conditions outlined in this cancellation and refund policy.</p>
                  
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RefundPolicy;
