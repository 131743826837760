import Cover from '../../1_MediaAssets/Homepage/Texture.png';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer';
// eslint-disable-next-line
import CourseCoverSample from '../../1_MediaAssets/MainPages/About1.jpg';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import { getFirestore, collection, doc, addDoc, serverTimestamp, updateDoc, getDocs, query, where} from "firebase/firestore";
import { useState,useEffect } from "react";

const UpcomingCourses = () => { 

    

    const db = getFirestore();
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        var data = [];
        const coursesCollection = collection(db, 'Courses');
        const q = query(coursesCollection, where("HiddenCourse", "!=", "Yes")); // Query to exclude hidden courses
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                data.push({...doc.data(), id: doc.id});
            });
    
            // Sort the data by StartDate
            data.sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate));
    
            setCourses(data);
        }); 
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.title = "Upcoming Courses | REX Medical Events"; // Set the document title if course is already available
    }, []);

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        const options = { day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    const createSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '');
    };

    function navigateCourse(course){
        const slug = createSlug(course.Name);
        navigate(`/Course/${slug}`, { state: { course: course } });
    }

    return (
        <> 
            <Navbar/>
            <div style={{ padding: '50px', backgroundImage:`url('${Cover}')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#d5faec', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100vw', height: '100%', flexDirection: 'column' }}>
            <h1 style={{ padding: '0', margin: '40px 0px 10px 0px', fontWeight: '900' }}>Upcoming <span className='fontSpecial' style={{color: '#1D5BC0'}}>Courses</span></h1>
            <p style={{ textAlign: 'center', width: '92vw', fontSize: '14px', maxWidth: '1200px', padding: '5px 0px 0px 0px' }}>At Rex Medical Events, we are proud to offer top-tier medical training courses designed for healthcare professionals worldwide. Guided by an esteemed international faculty with hands-on experience, our programs cater to General Practitioners, Urogynecologists, Surgeons, and Dermatologists. Participate in our courses to improve your expertise and broaden your knowledge in a supportive and engaging learning environment. </p>
            <div className="TrinityStripUC">
                {courses.map((course, i) => (
                <div key={i} onClick={() => { navigateCourse(course) }} className="TrinityStripElementUC">
                    <div className='CourseCover' style={{ backgroundImage: `url('${course.CoverPicture}')`}}>
                        <p style={{ position: 'absolute', bottom: '0', right: '0', background: '#000', color: 'white', fontSize: '9px', padding: '2px 10px 2px 10px', borderRadius: '5px 0px 3px 0px' }}>
                            {course.Type} Course
                        </p>
                        <p style={{ position: 'absolute', bottom: '0', left: '0', textAlign: 'center', background: 'red', color: 'white', fontSize: '9px', padding: '5px', borderRadius: '0px 20px 20px 0px', height: '23px', margin: '0', maxWidth: '100px' }}>
                            Only {course.Seats} seats left!
                        </p>
                    </div>
                    <h1 style={{ fontSize: '16px', marginTop: '10px', color: '#1D5BC0', width: '90%' }}>{course.Name}</h1>
                    <p style={{ fontSize: '12px', width: '90%', marginTop: '-7px', fontWeight: '900' }}>
                    {formatDate(course.StartDate)} - {formatDate(course.EndDate)} (GST)
                    </p>
                </div> 
                ))}
            </div>
            </div>
            <Footer />
        </>
        )
}

export default UpcomingCourses 